import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { LoginPayload } from 'api/actions/login/login-payload';
import { LoginPathParameters } from 'api/actions/login/login-path-parameters';
import { LoginResponse } from 'api/actions/login/login-response';

type LoginQueryParameters = Record<string, any>;

/**
 * The path of the Login action.
 */
export const loginActionPath = new SweetPath<keyof LoginPathParameters>('/login', { path: '{param}' });

/**
 * Creates a contextualized Login actions.
 */
export default function createLoginAction(axios: AxiosInstance) {
  return function loginAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: LoginQueryParameters;
    payload: LoginPayload;
  }) {
    const path = loginActionPath.original;

    return axios.post<LoginResponse, LoginResponse, LoginPayload>(path, payload);
  };
}
