import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UpdateLicensePlatePayload } from 'api/actions/update-license-plate/update-license-plate-payload';
import { UpdateLicensePlatePathParameters } from 'api/actions/update-license-plate/update-license-plate-path-parameters';
import { UpdateLicensePlateResponse } from 'api/actions/update-license-plate/update-license-plate-response';

type UpdateLicensePlateQueryParameters = Record<string, any>;

/**
 * The path of the UpdateLicensePlate action.
 */
export const updateLicensePlateActionPath = new SweetPath<keyof UpdateLicensePlatePathParameters>(
  '/user/license-plates/{licensePlateId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized UpdateLicensePlate actions.
 */
export default function createUpdateLicensePlateAction(axios: AxiosInstance) {
  return function updateLicensePlateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UpdateLicensePlatePathParameters;
    query?: UpdateLicensePlateQueryParameters;
    payload: UpdateLicensePlatePayload;
  }) {
    const path = updateLicensePlateActionPath.insert(parameters);

    return axios.put<UpdateLicensePlateResponse, UpdateLicensePlateResponse, UpdateLicensePlatePayload>(path, payload);
  };
}
