import { Box, Flex, Tabs, Button, Indicator } from '@mantine/core';
import { IconChevronLeft, IconMessage, IconPointFilled } from '@tabler/icons-react';
import React from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH, HELPDESK_PAGE_PATH } from 'routes/paths';
import P2Medium from 'theme/components/Typography/P2Medium';
import HelpDeskItem from 'components/HelpDeskItem';

const documentsData = [
  {
    id: 1,
    title: 'Nefunkčné svetlo na chodbe',
    time: '1. dec. 2023, 18:43',
    badges: [
      { variant: 'green', text: 'Prijaté' },
      { variant: 'white', text: 'byt' },
    ],
  },
  {
    id: 2,
    title: 'Problém s video vrátnikom',
    time: '4. dec. 2023, 18:43',
    badges: [
      { variant: 'orange', text: 'Pracuje sa na tom' },
      { variant: 'white', text: 'Objekt' },
    ],
  },
];

/**
 * MyDocuments page
 */
const MyDocuments: React.FC = () => {
  const { _t } = useLang();
  return (
    <>
      <SimpleLayout>
        <Box p="1rem">
          <Flex direction="row" align="center">
            <Link
              to={DASHBOARD_PATH.original}
              style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
            >
              <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
              <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back')}</P2Medium>
            </Link>
          </Flex>
          <Flex gap="0.5rem">
            <IconMessage size="2rem" color={theme.colors?.gray?.[12]} />
            <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('Helpdesk')}</H1SemiBold>
          </Flex>
        </Box>

        <Box>
          <Tabs defaultValue="gallery" variant="pills">
            <Tabs.List px="1rem" grow>
              <Tabs.Tab rightSection={<Indicator pl="0.3125rem" processing color="#FF364E" />} value="gallery">
                {_t('Notifications')}
              </Tabs.Tab>
              <Tabs.Tab value="messages">{_t('Disorders')}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" p="1rem">
              {documentsData.map((doc, index) => (
                <Link
                  key={doc.id}
                  to={HELPDESK_PAGE_PATH.original + '/' + doc.id}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <HelpDeskItem key={index} title={doc.title} size={doc.time} badges={doc.badges} />
                </Link>
              ))}
            </Tabs.Panel>
          </Tabs>
        </Box>
        <Button
          leftSection={<IconPointFilled style={{ color: '#19CD6C' }} />}
          variant="primary"
          style={{
            position: 'fixed',
            right: '1rem',
            bottom: '2.5rem',
            boxShadow: '0px 6px 12px 0px rgba(85, 31, 255, 0.30)',
          }}
          size="lg"
        >
          {_t('I need help')}
        </Button>
      </SimpleLayout>
    </>
  );
};

export default MyDocuments;
