import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OpenControlledEntryPathParameters } from 'api/actions/open-controlled-entry/open-controlled-entry-path-parameters';
import { OpenControlledEntryResponse } from 'api/actions/open-controlled-entry/open-controlled-entry-response';

type OpenControlledEntryQueryParameters = Record<string, any>;

/**
 * The path of the OpenControlledEntry action.
 */
export const openControlledEntryActionPath = new SweetPath<keyof OpenControlledEntryPathParameters>(
  '/controlled-entries/{controlledEntryId}/open',
  { path: '{param}' }
);

/**
 * Creates a contextualized OpenControlledEntry actions.
 */
export default function createOpenControlledEntryAction(axios: AxiosInstance) {
  return function openControlledEntryAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: OpenControlledEntryPathParameters;
    query?: OpenControlledEntryQueryParameters;
  }) {
    const path = openControlledEntryActionPath.insert(parameters);

    return axios.put<OpenControlledEntryResponse, OpenControlledEntryResponse>(path, { params: query, headers });
  };
}
