import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetControlledEntriesPathParameters } from 'api/actions/get-controlled-entries/get-controlled-entries-path-parameters';
import { GetControlledEntriesResponse } from 'api/actions/get-controlled-entries/get-controlled-entries-response';

type GetControlledEntriesQueryParameters = Record<string, any>;

/**
 * The path of the GetControlledEntries action.
 */
export const getControlledEntriesActionPath = new SweetPath<keyof GetControlledEntriesPathParameters>(
  '/controlled-entries',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetControlledEntries actions.
 */
export default function createGetControlledEntriesAction(axios: AxiosInstance) {
  return function getControlledEntriesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetControlledEntriesQueryParameters } = {}) {
    const path = getControlledEntriesActionPath.original;

    return axios.get<GetControlledEntriesResponse, GetControlledEntriesResponse>(path, { params: query, headers });
  };
}
