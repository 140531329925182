import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetSipDoorsPathParameters } from 'api/actions/get-sip-doors/get-sip-doors-path-parameters';
import { GetSipDoorsResponse } from 'api/actions/get-sip-doors/get-sip-doors-response';

type GetSipDoorsQueryParameters = Record<string, any>;

/**
 * The path of the GetSipDoors action.
 */
export const getSipDoorsActionPath = new SweetPath<keyof GetSipDoorsPathParameters>('/sip-doors', { path: '{param}' });

/**
 * Creates a contextualized GetSipDoors actions.
 */
export default function createGetSipDoorsAction(axios: AxiosInstance) {
  return function getSipDoorsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetSipDoorsQueryParameters } = {}) {
    const path = getSipDoorsActionPath.original;

    return axios.get<GetSipDoorsResponse, GetSipDoorsResponse>(path, { params: query, headers });
  };
}
