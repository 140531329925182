import { Modal, Radio } from '@mantine/core';
import React from 'react';
import useLang from 'lang';

interface ModalLocationProps {
  modalOpened: boolean;
  handleCloseModal: () => void;
  radioValue: string;
  handleRadioChange: (value: string) => void;
}
/**
 * ModalLocation component
 */
const ModalLocation: React.FC<ModalLocationProps> = ({ modalOpened, handleCloseModal, radioValue, handleRadioChange }) => {
  const { _t } = useLang();

  return (
    <Modal
      variant='secondary'
      opened={modalOpened}
      onClose={handleCloseModal}
      title={_t('Choose location')}
      transitionProps={{ transition: 'slide-up', duration: 400 }}
    >
      <Radio.Group value={radioValue} onChange={handleRadioChange}>
        <Radio value={_t('A flat')} label={_t('A flat')} />
        <Radio value={_t('Object')} label={_t('Object')} />
        <Radio value={_t('Area')} label={_t('Area')} />
      </Radio.Group>
    </Modal>
  );
};

export default ModalLocation;
