import { createContext, useContext } from 'react';
import { GetActionMap } from 'api/actions/actions';

/**
 * The session context interface.
 */
export interface IApiContext {
  /**
   * The JWT token.
   */
  jwt: string;

  /**
   * Returns the action with the given operationId.
   */
  getAction: GetActionMap;
}

/**
 * The session context is used to store the current session.
 *
 * The session context will be provided in <App>.
 */
export const ApiContext = createContext<IApiContext>(undefined!);

/**
 * The use API hook.
 */
export function useApi() {
  return useContext(ApiContext);
}
