import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { ChangePasswordPayload } from 'api/actions/change-password/change-password-payload';
import { ChangePasswordPathParameters } from 'api/actions/change-password/change-password-path-parameters';
import { ChangePasswordResponse } from 'api/actions/change-password/change-password-response';

type ChangePasswordQueryParameters = Record<string, any>;

/**
 * The path of the ChangePassword action.
 */
export const changePasswordActionPath = new SweetPath<keyof ChangePasswordPathParameters>('/change-password', {
  path: '{param}',
});

/**
 * Creates a contextualized ChangePassword actions.
 */
export default function createChangePasswordAction(axios: AxiosInstance) {
  return function changePasswordAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: ChangePasswordQueryParameters;
    payload: ChangePasswordPayload;
  }) {
    const path = changePasswordActionPath.original;

    return axios.post<ChangePasswordResponse, ChangePasswordResponse, ChangePasswordPayload>(path, payload);
  };
}
