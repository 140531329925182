import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OpenSipDoorPathParameters } from 'api/actions/open-sip-door/open-sip-door-path-parameters';
import { OpenSipDoorResponse } from 'api/actions/open-sip-door/open-sip-door-response';

type OpenSipDoorQueryParameters = Record<string, any>;

/**
 * The path of the OpenSipDoor action.
 */
export const openSipDoorActionPath = new SweetPath<keyof OpenSipDoorPathParameters>('/sip-doors/{sipDoorId}/open', {
  path: '{param}',
});

/**
 * Creates a contextualized OpenSipDoor actions.
 */
export default function createOpenSipDoorAction(axios: AxiosInstance) {
  return function openSipDoorAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: OpenSipDoorPathParameters;
    query?: OpenSipDoorQueryParameters;
  }) {
    const path = openSipDoorActionPath.insert(parameters);

    return axios.put<OpenSipDoorResponse, OpenSipDoorResponse>(path, { params: query, headers });
  };
}
