import React from 'react';
import { Box, Flex, Badge } from '@mantine/core';
import { IconEye, IconDownload } from '@tabler/icons-react';
import theme from 'theme';
import P1SemiBold from 'theme/components/Typography/P1SemiBold';
import P2Regular from 'theme/components/Typography/P2Regular';


interface DocumentItemProps {
    title: string;
    size: string;
    badges: Array<{ variant: string; text: string }>;
  }
/**
 * DocumentItem
 */
const DocumentItem : React.FC<DocumentItemProps> = ({ title, size, badges }) => (
  <Flex
    direction="row"
    justify='space-between'
    align="center"
    bg={theme.white}
    p="1rem"
    style={{ borderRadius: '0.625rem' }}
    mb='0.5rem'
  >
    <Box>
      <Flex gap="0.5rem" maw='15.625rem' wrap="wrap">
        {badges.map((badge, index) => (
          <Badge key={index} variant={badge.variant}>{badge.text}</Badge>
        ))}
      </Flex>
      <P1SemiBold color={theme.colors?.gray?.[12]} mt='0.5rem'>{title}</P1SemiBold>
      <P2Regular color={theme.colors?.gray?.[9]} mt='0.25rem'>{size}</P2Regular>
    </Box>
    <Flex>
      <IconEye size="1.5rem" />
      <div
        style={{ width: '0.0625rem', height: '1.5rem', backgroundColor: '#E0E0E0', margin: '0 16px' }}
      ></div>
      <IconDownload size="1.5rem" />
    </Flex>
  </Flex>
);

export default DocumentItem;
