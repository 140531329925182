import { Modal, Box, Flex } from '@mantine/core';
import { IconCamera, IconPhotoPlus } from '@tabler/icons-react';
import React from 'react';
import useLang from 'lang';
import P1Medium from 'theme/components/Typography/P1Medium';

interface ModalFileProps {
  secondModalOpened: boolean;
  handleCloseSecondModal: () => void;
}
/**
 * ModalFile component
 */
const ModalFile: React.FC<ModalFileProps> = ({ secondModalOpened, handleCloseSecondModal }) => {
  const { _t } = useLang();

  return (
    <Modal
      variant="secondary"
      opened={secondModalOpened}
      onClose={handleCloseSecondModal}
      title={_t('File')}
      transitionProps={{ transition: 'slide-up', duration: 400 }}
    >
      <Box>
        <Box>
          <Flex gap="0.5rem">
            <IconCamera />
            <P1Medium>{_t('Take photo')}</P1Medium>
          </Flex>
          <Flex gap="0.5rem">
            <IconPhotoPlus />
            <P1Medium>{_t('Choose from the device')}</P1Medium>
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalFile;
