import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P2Regular as P2RegularStyle } from 'theme/typography';

interface P2RegularProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P2Regular typography style.
 */
const P2Regular: React.FC<P2RegularProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P2RegularStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P2Regular;
