import createServerTimeAction from 'api/actions/server-time/server-time-action';
import createValidateAccessTokenAction from 'api/actions/validate-access-token/validate-access-token-action';
import createLoginAction from 'api/actions/login/login-action';
import createLogoutAction from 'api/actions/logout/logout-action';
import createGetCurrentUserAction from 'api/actions/get-current-user/get-current-user-action';
import createUpdateSessionAction from 'api/actions/update-session/update-session-action';
import createUpdateUserSettingsAction from 'api/actions/update-user-settings/update-user-settings-action';
import createGetUserNotificationsAction from 'api/actions/get-user-notifications/get-user-notifications-action';
import createSeenNotificationAction from 'api/actions/seen-notification/seen-notification-action';
import createGetLicensePlatesAction from 'api/actions/get-license-plates/get-license-plates-action';
import createCreateLicensePlateAction from 'api/actions/create-license-plate/create-license-plate-action';
import createDeleteLicensePlateAction from 'api/actions/delete-license-plate/delete-license-plate-action';
import createUpdateLicensePlateAction from 'api/actions/update-license-plate/update-license-plate-action';
import createGetFaqsAction from 'api/actions/get-faqs/get-faqs-action';
import createGetArticlesAction from 'api/actions/get-articles/get-articles-action';
import createGetArticleAction from 'api/actions/get-article/get-article-action';
import createInitPasswordResetAction from 'api/actions/init-password-reset/init-password-reset-action';
import createInitPasswordChangeAction from 'api/actions/init-password-change/init-password-change-action';
import createChangePasswordAction from 'api/actions/change-password/change-password-action';
import createGetSipDoorsAction from 'api/actions/get-sip-doors/get-sip-doors-action';
import createOpenSipDoorAction from 'api/actions/open-sip-door/open-sip-door-action';
import createDoorBellPressedAction from 'api/actions/door-bell-pressed/door-bell-pressed-action';
import createSipCallTerminatedAction from 'api/actions/sip-call-terminated/sip-call-terminated-action';
import createSipCallConnectingAction from 'api/actions/sip-call-connecting/sip-call-connecting-action';
import createGetCamerasAction from 'api/actions/get-cameras/get-cameras-action';
import createGetCameraLiveStreamAction from 'api/actions/get-camera-live-stream/get-camera-live-stream-action';
import createGetControlledEntriesAction from 'api/actions/get-controlled-entries/get-controlled-entries-action';
import createOpenControlledEntryAction from 'api/actions/open-controlled-entry/open-controlled-entry-action';
import createCloseControlledEntryAction from 'api/actions/close-controlled-entry/close-controlled-entry-action';
import createGetSensorsAction from 'api/actions/get-sensors/get-sensors-action';
import createGetSensorAction from 'api/actions/get-sensor/get-sensor-action';
import createGetSensorStatsAction from 'api/actions/get-sensor-stats/get-sensor-stats-action';
import createGetSensorAlertSettingsAction from 'api/actions/get-sensor-alert-settings/get-sensor-alert-settings-action';
import createUpdateSensorAlertSettingsAction from 'api/actions/update-sensor-alert-settings/update-sensor-alert-settings-action';
import { AxiosInstance } from 'axios';

/**
 * All action names.
 */
export type ActionName =
  | 'ServerTime'
  | 'ValidateAccessToken'
  | 'Login'
  | 'Logout'
  | 'GetCurrentUser'
  | 'UpdateSession'
  | 'UpdateUserSettings'
  | 'GetUserNotifications'
  | 'SeenNotification'
  | 'GetLicensePlates'
  | 'CreateLicensePlate'
  | 'DeleteLicensePlate'
  | 'UpdateLicensePlate'
  | 'GetFaqs'
  | 'GetArticles'
  | 'GetArticle'
  | 'InitPasswordReset'
  | 'InitPasswordChange'
  | 'ChangePassword'
  | 'GetSipDoors'
  | 'OpenSipDoor'
  | 'DoorBellPressed'
  | 'SipCallTerminated'
  | 'SipCallConnecting'
  | 'GetCameras'
  | 'GetCameraLiveStream'
  | 'GetControlledEntries'
  | 'OpenControlledEntry'
  | 'CloseControlledEntry'
  | 'GetSensors'
  | 'GetSensor'
  | 'GetSensorStats'
  | 'GetSensorAlertSettings'
  | 'UpdateSensorAlertSettings';

/**
 * Overloads for the getAction function.
 */
export type GetActionMap = ((action: 'ServerTime') => ReturnType<typeof createServerTimeAction>) &
  ((action: 'ValidateAccessToken') => ReturnType<typeof createValidateAccessTokenAction>) &
  ((action: 'Login') => ReturnType<typeof createLoginAction>) &
  ((action: 'Logout') => ReturnType<typeof createLogoutAction>) &
  ((action: 'GetCurrentUser') => ReturnType<typeof createGetCurrentUserAction>) &
  ((action: 'UpdateSession') => ReturnType<typeof createUpdateSessionAction>) &
  ((action: 'UpdateUserSettings') => ReturnType<typeof createUpdateUserSettingsAction>) &
  ((action: 'GetUserNotifications') => ReturnType<typeof createGetUserNotificationsAction>) &
  ((action: 'SeenNotification') => ReturnType<typeof createSeenNotificationAction>) &
  ((action: 'GetLicensePlates') => ReturnType<typeof createGetLicensePlatesAction>) &
  ((action: 'CreateLicensePlate') => ReturnType<typeof createCreateLicensePlateAction>) &
  ((action: 'DeleteLicensePlate') => ReturnType<typeof createDeleteLicensePlateAction>) &
  ((action: 'UpdateLicensePlate') => ReturnType<typeof createUpdateLicensePlateAction>) &
  ((action: 'GetFaqs') => ReturnType<typeof createGetFaqsAction>) &
  ((action: 'GetArticles') => ReturnType<typeof createGetArticlesAction>) &
  ((action: 'GetArticle') => ReturnType<typeof createGetArticleAction>) &
  ((action: 'InitPasswordReset') => ReturnType<typeof createInitPasswordResetAction>) &
  ((action: 'InitPasswordChange') => ReturnType<typeof createInitPasswordChangeAction>) &
  ((action: 'ChangePassword') => ReturnType<typeof createChangePasswordAction>) &
  ((action: 'GetSipDoors') => ReturnType<typeof createGetSipDoorsAction>) &
  ((action: 'OpenSipDoor') => ReturnType<typeof createOpenSipDoorAction>) &
  ((action: 'DoorBellPressed') => ReturnType<typeof createDoorBellPressedAction>) &
  ((action: 'SipCallTerminated') => ReturnType<typeof createSipCallTerminatedAction>) &
  ((action: 'SipCallConnecting') => ReturnType<typeof createSipCallConnectingAction>) &
  ((action: 'GetCameras') => ReturnType<typeof createGetCamerasAction>) &
  ((action: 'GetCameraLiveStream') => ReturnType<typeof createGetCameraLiveStreamAction>) &
  ((action: 'GetControlledEntries') => ReturnType<typeof createGetControlledEntriesAction>) &
  ((action: 'OpenControlledEntry') => ReturnType<typeof createOpenControlledEntryAction>) &
  ((action: 'CloseControlledEntry') => ReturnType<typeof createCloseControlledEntryAction>) &
  ((action: 'GetSensors') => ReturnType<typeof createGetSensorsAction>) &
  ((action: 'GetSensor') => ReturnType<typeof createGetSensorAction>) &
  ((action: 'GetSensorStats') => ReturnType<typeof createGetSensorStatsAction>) &
  ((action: 'GetSensorAlertSettings') => ReturnType<typeof createGetSensorAlertSettingsAction>) &
  ((action: 'UpdateSensorAlertSettings') => ReturnType<typeof createUpdateSensorAlertSettingsAction>);

/**
 * The map from action name to action creator.
 */
export const actions: Map<ActionName, (axios: AxiosInstance) => Promise<any>> = new Map([
  ['ServerTime', createServerTimeAction],
  ['ValidateAccessToken', createValidateAccessTokenAction],
  ['Login', createLoginAction],
  ['Logout', createLogoutAction],
  ['GetCurrentUser', createGetCurrentUserAction],
  ['UpdateSession', createUpdateSessionAction],
  ['UpdateUserSettings', createUpdateUserSettingsAction],
  ['GetUserNotifications', createGetUserNotificationsAction],
  ['SeenNotification', createSeenNotificationAction],
  ['GetLicensePlates', createGetLicensePlatesAction],
  ['CreateLicensePlate', createCreateLicensePlateAction],
  ['DeleteLicensePlate', createDeleteLicensePlateAction],
  ['UpdateLicensePlate', createUpdateLicensePlateAction],
  ['GetFaqs', createGetFaqsAction],
  ['GetArticles', createGetArticlesAction],
  ['GetArticle', createGetArticleAction],
  ['InitPasswordReset', createInitPasswordResetAction],
  ['InitPasswordChange', createInitPasswordChangeAction],
  ['ChangePassword', createChangePasswordAction],
  ['GetSipDoors', createGetSipDoorsAction],
  ['OpenSipDoor', createOpenSipDoorAction],
  ['DoorBellPressed', createDoorBellPressedAction],
  ['SipCallTerminated', createSipCallTerminatedAction],
  ['SipCallConnecting', createSipCallConnectingAction],
  ['GetCameras', createGetCamerasAction],
  ['GetCameraLiveStream', createGetCameraLiveStreamAction],
  ['GetControlledEntries', createGetControlledEntriesAction],
  ['OpenControlledEntry', createOpenControlledEntryAction],
  ['CloseControlledEntry', createCloseControlledEntryAction],
  ['GetSensors', createGetSensorsAction],
  ['GetSensor', createGetSensorAction],
  ['GetSensorStats', createGetSensorStatsAction],
  ['GetSensorAlertSettings', createGetSensorAlertSettingsAction],
  ['UpdateSensorAlertSettings', createUpdateSensorAlertSettingsAction],
] as any);
