import { SweetPath } from 'sweet-path';

// Dashboard
export const DASHBOARD_PATH = new SweetPath('/');

// MyDocumentsPage
export const MYDOCUMENTS_PAGE_PATH = new SweetPath('/mydocuments');

//  InstructionsPage
export const INSTRUCTIONS_PAGE_PATH = new SweetPath('/instructions');

//  InstructionsPageDetail
export const INSTRUCTIONS_DETAIL_PAGE_PATH = new SweetPath('/instructions/:id');

//  FAQPage
export const FAQ_PAGE_PATH = new SweetPath('/faq');

//  HelpdeskPage
export const HELPDESK_PAGE_PATH = new SweetPath('/helpdesk');

//  HelpdeskPageDetail
export const HELPDESK_DETAIL_PAGE_PATH = new SweetPath('/helpdesk/:id');

//  TicketPage
export const TICKET_PAGE_PATH = new SweetPath('/ticket');

//  HousePage
export const HOUSE_PAGE_PATH = new SweetPath('/house');

//  EcvPage
export const ECV_PAGE_PATH = new SweetPath('/ecv');

//  EcvAddPage
export const ECV_ADD_PAGE_PATH = new SweetPath('/ecv-add');

//  EcvEditPage
export const ECV_EDIT_PAGE_PATH = new SweetPath('/ecv/:id');
