import { Badge, Box, Flex, Overlay, Image, Avatar, Textarea, Button } from '@mantine/core';
import React from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import { Link, useParams } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight, IconEye, IconPaperclip } from '@tabler/icons-react';
import P2Medium from 'theme/components/Typography/P2Medium';
import { HELPDESK_PAGE_PATH } from 'routes/paths';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import P1Regular from 'theme/components/Typography/P1Regular';
import P1Medium from 'theme/components/Typography/P1Medium';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import P2Regular from 'theme/components/Typography/P2Regular';

const data = [
  {
    id: 1,
    title: 'Nefunkčné svetlo na chodbe',
    description: 'Popis problému / formulári sa budú dať budúcnosti meniť prostredníctvom aplikácie Phillip.',
    date: '4. dec. 2023, 18:43',
    author: 'Marek Môcik',
    location_variant: 'white',
    location_text: 'objekt',
    status_variant: 'orange',
    status_text: 'Pracuje sa na tom',
    files: [
      { src: '../img/homescreen.jpeg', alt: 'Image 1' },
      { src: '../img/schematic.png', alt: 'Image 2' },
    ],
    comments: [
      {
        author: 'Marek Môcik',
        date: '4. December 2023, 18:43',
        text: 'Prosím vás, ako dlho to bude trváť ? Údaje zadané v aktivačnom dotazníku.',
        files: [
          { src: '../img/homescreen.jpeg', alt: 'Image 1' },
          { src: '../img/schematic.png', alt: 'Image 2' },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Nefunkčné svetlo na chodbe',
    description: 'Popis problému / formulári sa budú dať budúcnosti meniť prostredníctvom aplikácie Phillip.',
    date: '4. dec. 2023, 18:43',
    author: 'Marek Môcik',
    location_variant: 'white',
    location_text: 'objekt',
    status_variant: 'orange',
    status_text: 'Pracuje sa na tom',
    files: [
      { src: '../img/homescreen.jpeg', alt: 'Image 1' },
      { src: '../img/schematic.png', alt: 'Image 2' },
    ],
    comments: [
      {
        author: 'Marek Môcik',
        date: '4. December 2023, 18:43',
        text: 'Prosím vás, ako dlho to bude trváť ? Údaje zadané v aktivačnom dotazníku.',
        files: [
          { src: '../img/homescreen.jpeg', alt: 'Image 1' },
          { src: '../img/schematic.png', alt: 'Image 2' },
        ],
      },
      {
        author: 'Marek Môcik',
        date: '4. December 2023, 18:43',
        text: 'Prosím vás, ako dlho to bude trváť ? Údaje zadané v aktivačnom dotazníku.',
        files: [{ src: '../img/homescreen.jpeg', alt: 'Image 1' }],
      },
      {
        author: 'Marek Môcik',
        date: '4. December 2023, 18:43',
        text: 'Prosím vás, ako dlho to bude trváť ? Údaje zadané v aktivačnom dotazníku.',
        files: [],
      },
    ],
  },
];

/**
 * HelpDeskDetail page
 */
const HelpDeskDetail: React.FC = () => {
  const { _t } = useLang();
  const { id } = useParams<{ id: string }>();
  const dataId = Number(id);
  const HelpDesk = data.find((data) => data.id === dataId);

  const [opened, setOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [hovered, setHovered] = useState<string | null>(null);

  /**
   * Click handler
   */
  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    setOpened(true);
  };

  if (!HelpDesk) {
    return <div>{_t('No requests found!')}</div>;
  }

  return (
    <SimpleLayout>
      <Box p="1rem" bg={theme.white}>
        <Flex direction="row" align="center">
          <Link
            to={HELPDESK_PAGE_PATH.original}
            style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
          >
            <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
            <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back to Helpdesk')}</P2Medium>
          </Link>
        </Flex>
        <H1SemiBold mt="0.75rem" color={theme.colors?.gray?.[12]}>
          {HelpDesk.title}
        </H1SemiBold>
        <P1Regular mt="0.25rem" pb="1rem" color={theme.colors?.gray?.[12]}>
          {HelpDesk.description}
        </P1Regular>
        <Flex
          py="1rem"
          gap="0.75rem"
          direction="column"
          style={{ borderTop: '1px solid #DFE2E6', borderBottom: '1px solid #DFE2E6' }}
        >
          <H2SemiBold color={theme.colors?.gray?.[12]}>{_t('Information')}</H2SemiBold>

          <Flex direction="row" justify="space-between">
            <P1Regular color={theme.colors?.gray?.[10]}>{_t('Date of reporting')}</P1Regular>
            <P1Medium color={theme.colors?.gray?.[12]}>{HelpDesk.date}</P1Medium>
          </Flex>
          <Flex direction="row" justify="space-between">
            <P1Regular color={theme.colors?.gray?.[10]}>{_t('Reported')}</P1Regular>
            <P1Medium color={theme.colors?.gray?.[10]}>{HelpDesk.author}</P1Medium>
          </Flex>
          <Flex direction="row" justify="space-between">
            <P1Regular color={theme.colors?.gray?.[10]}>{_t('Location')}</P1Regular>
            <Badge key={id} variant={HelpDesk.location_variant}>
              {HelpDesk.location_text}
            </Badge>
          </Flex>
          <Flex direction="row" justify="space-between">
            <P1Regular color={theme.colors?.gray?.[10]}>{_t('Status')}</P1Regular>
            <Badge key={id} variant={HelpDesk.status_variant}>
              {HelpDesk.status_text}
            </Badge>
          </Flex>
        </Flex>
        <Box py="1rem">
          <H2SemiBold color={theme.colors?.gray?.[12]}>{_t('Files')}</H2SemiBold>
          <Flex mt="0.75rem" gap="10px" py="0.5rem">
            {HelpDesk.files.map((file) => (
              <>
                <Box
                  style={{ width: '88px', height: '88px', position: 'relative' }}
                  onMouseEnter={() => setHovered(file.src)}
                  onMouseLeave={() => setHovered(null)}
                  onClick={() => handleImageClick(file.src)}
                >
                  <Image src={file.src} alt={file.alt} style={{ borderRadius: '10px' }} />
                  {hovered === file.src && (
                    <Overlay style={{ borderRadius: '10px' }} center>
                      <IconEye color={theme.white} />
                    </Overlay>
                  )}
                </Box>
              </>
            ))}
          </Flex>
        </Box>
        <Flex
          py="1rem"
          gap="1.5rem"
          direction="column"
          style={{ borderTop: '1px solid #DFE2E6', borderBottom: '1px solid #DFE2E6' }}
        >
          <H2SemiBold color={theme.colors?.gray?.[12]}>{_t('Comments')}</H2SemiBold>
          {HelpDesk.comments.map((comment, index) => (
            <Flex key={index} gap="0.75rem">
              <Avatar size="2.75rem">{comment.author[0]}</Avatar>
              <Flex direction="column">
                <P1Medium color={theme.black}>{comment.author}</P1Medium>
                <P2Regular color={theme.colors?.gray?.[9]}>{comment.date}</P2Regular>
                <P1Regular color={theme.colors?.gray?.[12]} pt="0.5rem">
                  {comment.text}
                </P1Regular>
                <Flex mt="0.75rem" gap="10px" py="0.5rem" direction="row">
                  {comment.files.map((file) => (
                    <Box
                      key={file.src}
                      style={{ width: '88px', height: '88px', position: 'relative' }}
                      onMouseEnter={() => setHovered(file.src)}
                      onMouseLeave={() => setHovered(null)}
                      onClick={() => handleImageClick(file.src)}
                    >
                      <Image src={file.src} alt={file.alt} style={{ borderRadius: '10px' }} />
                      {hovered === file.src && (
                        <Overlay style={{ borderRadius: '10px' }} center>
                          <IconEye color={theme.white} />
                        </Overlay>
                      )}
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>

      <Modal opened={opened} onClose={() => setOpened(false)} centered>
        <img src={selectedImage} alt="Selected" width="100%" />
      </Modal>
      <Box style={{ position: 'sticky', bottom: '0rem' }} w="100%">
        <Textarea
          placeholder={_t('Write a comment ...')}
          size="md"
          w="100%"
          pt="1rem"
          px="1rem"
          bg={theme.white}
          style={{
            borderRadius: '10px 10px 0px  0px',
            borderTop: '1px solid #DFE2E6',
            boxShadow: '0px -4px 12px 0px #00000033',
          }}
        />
        <Flex bg={theme.white} justify="space-between" align="center" pb="1rem" px="1rem">
          <IconPaperclip color={theme.colors?.gray?.[7]} />
          <Button variant="primary" rightSection={<IconChevronRight />}>
            {_t('Send')}
          </Button>
        </Flex>
      </Box>
    </SimpleLayout>
  );
};

export default HelpDeskDetail;
