import {
  IconArmchair2,
  IconBook,
  IconChevronRight,
  IconHelpHexagon,
  IconMessage,
  IconScript,
  IconTicket,
} from '@tabler/icons-react';
import useLang from 'lang';
import {
  FAQ_PAGE_PATH,
  HELPDESK_PAGE_PATH,
  HOUSE_PAGE_PATH,
  INSTRUCTIONS_PAGE_PATH,
  MYDOCUMENTS_PAGE_PATH,
  TICKET_PAGE_PATH,
} from 'routes/paths';
import MenuItem from 'components/MenuItem';

/**
 * Menu component
 */
const Menu = () => {
  const { _t } = useLang();

  return (
    <>
      {/*Home */}
      <MenuItem
        to={HOUSE_PAGE_PATH.original}
        icon={IconArmchair2}
        title={_t('My household')}
        description={_t('Overview of your invoices, contracts ...')}
        iconRight={IconChevronRight}
      />

      {/*MyDocuments*/}
      <MenuItem
        to={MYDOCUMENTS_PAGE_PATH.original}
        icon={IconScript}
        title={_t('My Documents')}
        description={_t('List of your documents')}
        iconRight={IconChevronRight}
      />
      {/*Instructions */}
      <MenuItem
        to={INSTRUCTIONS_PAGE_PATH.original}
        icon={IconBook}
        title={_t('Instructions')}
        description={_t('Instructions for operation and settings of smart devices')}
        iconRight={IconChevronRight}
      />

      {/*FAQ */}
      <MenuItem
        to={FAQ_PAGE_PATH.original}
        icon={IconHelpHexagon}
        title={_t('FAQ')}
        description={_t('Frequently asked questions')}
        iconRight={IconChevronRight}
      />
      {/*Helpdesk */}
      <MenuItem
        to={HELPDESK_PAGE_PATH.original}
        icon={IconMessage}
        title={_t('Ask')}
        description={_t('Online chat for your questions')}
        iconRight={IconChevronRight}
      />
      {/*Ticket */}
      <MenuItem
        to={TICKET_PAGE_PATH.original}
        icon={IconTicket}
        title={_t('Add a ticket')}
        description={_t('Temporary')}
        iconRight={IconChevronRight}
      />
    </>
  );
};

export default Menu;
