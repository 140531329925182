import { Box, Flex } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';

import { Link } from 'react-router-dom';
import { IconBook, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import P2Medium from 'theme/components/Typography/P2Medium';
import { DASHBOARD_PATH, INSTRUCTIONS_PAGE_PATH } from 'routes/paths';
import P1SemiBold from 'theme/components/Typography/P1SemiBold';
import P2Regular from 'theme/components/Typography/P2Regular';
import { GetArticlesResponse } from 'api/actions/get-articles/get-articles-response';
import { useApi } from 'api/api-context';
import panic from 'panic';
import Loader from 'components/Loader';

type Articles = NonNullable<GetArticlesResponse['articles']>;

/**
 * Instructions page
 */
const Instructions: React.FC = () => {
  const { _t } = useLang();
  const { getAction } = useApi();
  const [articles, setArticles] = useState<Articles>([]);
  const [loading, setLoading] = useState(false);

  /**
   * Load Articles
   */
  const loadArticles = () => {
    const getArticles = getAction('GetArticles');
    setLoading(true);

    getArticles()
      .then(({ articles }) => {
        setArticles(articles || []);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  };

  /**
   * Use effect to load FAQ on component
   */
  useEffect(() => {
    loadArticles();
  }, [getAction]);
  return (
    <>
      <SimpleLayout>
        <Box p="1rem">
          <Flex direction="row" align="center">
            <Link
              to={DASHBOARD_PATH.original}
              style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
            >
              <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
              <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back')}</P2Medium>
            </Link>
          </Flex>
          <Flex gap="0.5rem">
            <IconBook size="2rem" color={theme.colors?.gray?.[12]} />
            <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('Instructions')}</H1SemiBold>
          </Flex>
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <>
            {' '}
            <Box px="1rem">
              {articles.map((article) => (
                <Link
                  key={article.id}
                  to={INSTRUCTIONS_PAGE_PATH.original + '/' + article.id}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Flex
                    direction="row"
                    gap="1rem"
                    align="center"
                    bg={theme.white}
                    p="1rem"
                    style={{ borderRadius: '0.625rem' }}
                    mb="0.5rem"
                    justify="space-between"
                  >
                    <Box w="fit-content">
                      <P1SemiBold color={theme.colors?.gray?.[12]}>{article.title}</P1SemiBold>
                      <P2Regular color={theme.colors?.gray?.[10]}>{article.excerpt}</P2Regular>
                    </Box>
                    <IconChevronRight size="1.5rem" color={theme.colors?.gray?.[7]} />
                  </Flex>
                </Link>
              ))}
            </Box>
          </>
        )}
      </SimpleLayout>
    </>
  );
};

export default Instructions;
