// cspell:disable

/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/generate-environment.ts. If you wish to
 * change the environment variables, modify the file config/environment.yaml and then run:
 *
 *     npm run generate:environment
 *
 * -------------------------------------------------------------------------------------------------
 */

import getenv from 'env/getenv';

/**
 * The base url of the REST API.
 */
export const REST_API_URL =
  process.env.REACT_APP_REST_API_URL ||
  getenv('REST_API_URL', {
    development: 'https://admin.phillip.city/api/v1',
    test: 'https://admin.phillip.city/api/v1',
    production: 'https://admin.phillip.city/api/v1',
  });

/**
 * The timeout for the REST API.
 */
export const REST_API_TIMEOUT = Number(
  process.env.REACT_APP_REST_API_TIMEOUT ||
    getenv('REST_API_TIMEOUT', {
      development: '60000',
      test: '60000',
      production: '60000',
    })
);
