import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetCameraLiveStreamPathParameters } from 'api/actions/get-camera-live-stream/get-camera-live-stream-path-parameters';
import { GetCameraLiveStreamResponse } from 'api/actions/get-camera-live-stream/get-camera-live-stream-response';

type GetCameraLiveStreamQueryParameters = Record<string, any>;

/**
 * The path of the GetCameraLiveStream action.
 */
export const getCameraLiveStreamActionPath = new SweetPath<keyof GetCameraLiveStreamPathParameters>(
  '/cameras/{cameraId}/live',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetCameraLiveStream actions.
 */
export default function createGetCameraLiveStreamAction(axios: AxiosInstance) {
  return function getCameraLiveStreamAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: GetCameraLiveStreamPathParameters;
    query?: GetCameraLiveStreamQueryParameters;
  }) {
    const path = getCameraLiveStreamActionPath.insert(parameters);

    return axios.get<GetCameraLiveStreamResponse, GetCameraLiveStreamResponse>(path, { params: query, headers });
  };
}
