import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { ServerTimePathParameters } from 'api/actions/server-time/server-time-path-parameters';
import { ServerTimeResponse } from 'api/actions/server-time/server-time-response';

type ServerTimeQueryParameters = Record<string, any>;

/**
 * The path of the ServerTime action.
 */
export const serverTimeActionPath = new SweetPath<keyof ServerTimePathParameters>('/time', { path: '{param}' });

/**
 * Creates a contextualized ServerTime actions.
 */
export default function createServerTimeAction(axios: AxiosInstance) {
  return function serverTimeAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: ServerTimeQueryParameters } = {}) {
    const path = serverTimeActionPath.original;

    return axios.get<ServerTimeResponse, ServerTimeResponse>(path, { params: query, headers });
  };
}
