import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';

/**
 * Function to call when an unexpected error occurs.
 */
export default function panic(error: Error): void {
  if (error instanceof AxiosError && error.response?.status === 401) {
    return; // No need to show notification, user will be logged out.
  }

  const message = error.message || error.toString();

  notifications.show({
    title: 'Unexpected error',
    message,
    color: 'red',
    autoClose: false,
  });
}
