import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SipCallTerminatedPayload } from 'api/actions/sip-call-terminated/sip-call-terminated-payload';
import { SipCallTerminatedPathParameters } from 'api/actions/sip-call-terminated/sip-call-terminated-path-parameters';
import { SipCallTerminatedResponse } from 'api/actions/sip-call-terminated/sip-call-terminated-response';

type SipCallTerminatedQueryParameters = Record<string, any>;

/**
 * The path of the SipCallTerminated action.
 */
export const sipCallTerminatedActionPath = new SweetPath<keyof SipCallTerminatedPathParameters>(
  '/sip/call/terminated',
  { path: '{param}' }
);

/**
 * Creates a contextualized SipCallTerminated actions.
 */
export default function createSipCallTerminatedAction(axios: AxiosInstance) {
  return function sipCallTerminatedAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: SipCallTerminatedQueryParameters;
    payload: SipCallTerminatedPayload;
  }) {
    const path = sipCallTerminatedActionPath.original;

    return axios.post<SipCallTerminatedResponse, SipCallTerminatedResponse, SipCallTerminatedPayload>(path, payload);
  };
}
