import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DoorBellPressedPayload } from 'api/actions/door-bell-pressed/door-bell-pressed-payload';
import { DoorBellPressedPathParameters } from 'api/actions/door-bell-pressed/door-bell-pressed-path-parameters';
import { DoorBellPressedResponse } from 'api/actions/door-bell-pressed/door-bell-pressed-response';

type DoorBellPressedQueryParameters = Record<string, any>;

/**
 * The path of the DoorBellPressed action.
 */
export const doorBellPressedActionPath = new SweetPath<keyof DoorBellPressedPathParameters>(
  '/sip-doors/{sipDoorId}/door-bells/pressed',
  { path: '{param}' }
);

/**
 * Creates a contextualized DoorBellPressed actions.
 */
export default function createDoorBellPressedAction(axios: AxiosInstance) {
  return function doorBellPressedAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DoorBellPressedPathParameters;
    query?: DoorBellPressedQueryParameters;
    payload: DoorBellPressedPayload;
  }) {
    const path = doorBellPressedActionPath.insert(parameters);

    return axios.post<DoorBellPressedResponse, DoorBellPressedResponse, DoorBellPressedPayload>(path, payload);
  };
}
