import { Input } from '@mantine/core';
import classNames from 'theme/components/Inputs/Input/Input.module.css';

export default Input.extend({
  classNames,
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});


