import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetSensorPathParameters } from 'api/actions/get-sensor/get-sensor-path-parameters';
import { GetSensorResponse } from 'api/actions/get-sensor/get-sensor-response';

type GetSensorQueryParameters = Record<string, any>;

/**
 * The path of the GetSensor action.
 */
export const getSensorActionPath = new SweetPath<keyof GetSensorPathParameters>('/sensors/{sensorId}', {
  path: '{param}',
});

/**
 * Creates a contextualized GetSensor actions.
 */
export default function createGetSensorAction(axios: AxiosInstance) {
  return function getSensorAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: GetSensorPathParameters;
    query?: GetSensorQueryParameters;
  }) {
    const path = getSensorActionPath.insert(parameters);

    return axios.get<GetSensorResponse, GetSensorResponse>(path, { params: query, headers });
  };
}
