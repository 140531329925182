import { Accordion, Box, Flex, Skeleton, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';

import { Link } from 'react-router-dom';
import { IconChevronLeft, IconHelpHexagon } from '@tabler/icons-react';
import P2Medium from 'theme/components/Typography/P2Medium';
import { DASHBOARD_PATH } from 'routes/paths';
import P1SemiBold from 'theme/components/Typography/P1SemiBold';
import P1Regular from 'theme/components/Typography/P1Regular';
import { useApi } from 'api/api-context';
import panic from 'panic';
import { GetFaqsResponse } from 'api/actions/get-faqs/get-faqs-response';

type Faqs = NonNullable<GetFaqsResponse['faqs']>;

/**
 * Loader for ECV.
 */
const EcvLoader: React.FC = () => {
  return (
    <Stack px="1rem">
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
    </Stack>
  );
};

/**
 * Faq page
 */
const Faq: React.FC = () => {
  const { _t } = useLang();
  const { getAction } = useApi();
  const [faqs, setFaqs] = useState<Faqs>([]);
  const [loading, setLoading] = useState(false);

  /**
   * Load Faqs
   */
  const loadFaqs = () => {
    const getFaqs = getAction('GetFaqs');

    setLoading(true);

    getFaqs()
      .then(({ faqs }) => {
        setFaqs(faqs || []);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  };

  /**
   * Use effect to load FAQ on component
   */
  useEffect(() => {
    loadFaqs();
  }, [getAction]);

  return (
    <SimpleLayout>
      <Box p="1rem">
        <Flex direction="row" align="center">
          <Link
            to={DASHBOARD_PATH.original}
            style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
          >
            <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
            <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back')}</P2Medium>
          </Link>
        </Flex>
        <Flex gap="0.5rem">
          <IconHelpHexagon size="2rem" color={theme.colors?.gray?.[12]} />
          <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('FAQ')}</H1SemiBold>
        </Flex>
      </Box>

      {loading ? (
        <EcvLoader />
      ) : (
        <>
          <Box px="1rem">
            <Accordion variant="separated" radius="md" chevronSize="1.5rem">
              {faqs.length === 0 ? (
                <P1Regular>No articles available</P1Regular>
              ) : (
                faqs.map((faq) => (
                  <Accordion.Item key={faq.id} value={faq.question ?? 'defaultValue'}>
                    <Accordion.Control>
                      <P1SemiBold>{faq.question ?? 'No FAQ provided'}</P1SemiBold>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Box dangerouslySetInnerHTML={{ __html: faq.answer ?? '' }} />
                      {/* <P1Regular color={theme.colors?.gray?.[12]}>{faq.answer}</P1Regular> */}
                    </Accordion.Panel>
                  </Accordion.Item>
                ))
              )}
            </Accordion>
          </Box>
        </>
      )}
    </SimpleLayout>
  );
};

export default Faq;
