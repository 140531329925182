import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetUserNotificationsPathParameters } from 'api/actions/get-user-notifications/get-user-notifications-path-parameters';
import { GetUserNotificationsResponse } from 'api/actions/get-user-notifications/get-user-notifications-response';

type GetUserNotificationsQueryParameters = Record<string, any>;

/**
 * The path of the GetUserNotifications action.
 */
export const getUserNotificationsActionPath = new SweetPath<keyof GetUserNotificationsPathParameters>(
  '/user/notifications',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetUserNotifications actions.
 */
export default function createGetUserNotificationsAction(axios: AxiosInstance) {
  return function getUserNotificationsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetUserNotificationsQueryParameters } = {}) {
    const path = getUserNotificationsActionPath.original;

    return axios.get<GetUserNotificationsResponse, GetUserNotificationsResponse>(path, { params: query, headers });
  };
}
