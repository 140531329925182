import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { CreateLicensePlatePayload } from 'api/actions/create-license-plate/create-license-plate-payload';
import { CreateLicensePlatePathParameters } from 'api/actions/create-license-plate/create-license-plate-path-parameters';
import { CreateLicensePlateResponse } from 'api/actions/create-license-plate/create-license-plate-response';

type CreateLicensePlateQueryParameters = Record<string, any>;

/**
 * The path of the CreateLicensePlate action.
 */
export const createLicensePlateActionPath = new SweetPath<keyof CreateLicensePlatePathParameters>(
  '/user/license-plates',
  { path: '{param}' }
);

/**
 * Creates a contextualized CreateLicensePlate actions.
 */
export default function createCreateLicensePlateAction(axios: AxiosInstance) {
  return function createLicensePlateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: CreateLicensePlateQueryParameters;
    payload: CreateLicensePlatePayload;
  }) {
    const path = createLicensePlateActionPath.original;

    return axios.post<CreateLicensePlateResponse, CreateLicensePlateResponse, CreateLicensePlatePayload>(path, payload);
  };
}
