import { Box, Flex, Skeleton, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import { Link, useParams } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import P2Medium from 'theme/components/Typography/P2Medium';
import { INSTRUCTIONS_PAGE_PATH } from 'routes/paths';
import P2Regular from 'theme/components/Typography/P2Regular';
import { GetArticleResponse } from 'api/actions/get-article/get-article-response';
import { useApi } from 'api/api-context';
import panic from 'panic';

type Article = NonNullable<GetArticleResponse['article']>;

/**
 * Loader for ECV.
 */
const EcvLoader: React.FC = () => {
  return (
    <Stack px="1rem">
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
    </Stack>
  );
};

/**
 * InstructionDetail page
 */
const InstructionDetail: React.FC = () => {
  const { _t } = useLang();
  const { id } = useParams<{ id: string }>();
  const instructionId = Number(id);

  const { getAction } = useApi();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(false);

  /**
   * Load Article
   */
  const loadArticle = () => {
    const getArticle = getAction('GetArticle');
    setLoading(true);

    getArticle({ parameters: { articleId: instructionId } })
      .then(({ article }) => setArticle(article!))
      .catch(panic)
      .finally(() => setLoading(false));
  };

  /**
   * Use effect to load article on component mount
   */
  useEffect(() => {
    loadArticle();
  }, [instructionId, getAction]);

  return (
    <SimpleLayout>
      <Box p="1rem">
        <Flex direction="row" align="center">
          <Link
            to={INSTRUCTIONS_PAGE_PATH.original}
            style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
          >
            <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
            <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back to all tutorials')}</P2Medium>
          </Link>
        </Flex>
        {loading ? (
          <EcvLoader />
        ) : article ? (
          <>
            <H1SemiBold mt="0.75rem" color={theme.colors?.gray?.[12]}>
              {article.title}
            </H1SemiBold>
            <P2Regular mt="0.25rem" pb="1rem" color={theme.colors?.gray?.[12]}>
              {article.excerpt}
            </P2Regular>
            <Flex bg={theme.white} p="1rem" style={{ borderRadius: '0.625rem' }}>
              <div dangerouslySetInnerHTML={{ __html: article.content || '' }} />
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Box>
    </SimpleLayout>
  );
};

export default InstructionDetail;
