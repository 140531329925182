import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UpdateSessionPayload } from 'api/actions/update-session/update-session-payload';
import { UpdateSessionPathParameters } from 'api/actions/update-session/update-session-path-parameters';
import { UpdateSessionResponse } from 'api/actions/update-session/update-session-response';

type UpdateSessionQueryParameters = Record<string, any>;

/**
 * The path of the UpdateSession action.
 */
export const updateSessionActionPath = new SweetPath<keyof UpdateSessionPathParameters>('/user/session', {
  path: '{param}',
});

/**
 * Creates a contextualized UpdateSession actions.
 */
export default function createUpdateSessionAction(axios: AxiosInstance) {
  return function updateSessionAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UpdateSessionQueryParameters;
    payload: UpdateSessionPayload;
  }) {
    const path = updateSessionActionPath.original;

    return axios.put<UpdateSessionResponse, UpdateSessionResponse, UpdateSessionPayload>(path, payload);
  };
}
