import { Button } from '@mantine/core';
import classNames from 'theme/components/Buttons/Button/Button.module.css';

export default Button.extend({
  classNames,
  defaultProps: {
    variant: 'primary',
    size: 'sm',
  },
});
