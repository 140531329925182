import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { ValidateAccessTokenPayload } from 'api/actions/validate-access-token/validate-access-token-payload';
import { ValidateAccessTokenPathParameters } from 'api/actions/validate-access-token/validate-access-token-path-parameters';
import { ValidateAccessTokenResponse } from 'api/actions/validate-access-token/validate-access-token-response';

type ValidateAccessTokenQueryParameters = Record<string, any>;

/**
 * The path of the ValidateAccessToken action.
 */
export const validateAccessTokenActionPath = new SweetPath<keyof ValidateAccessTokenPathParameters>(
  '/access-token/validate',
  { path: '{param}' }
);

/**
 * Creates a contextualized ValidateAccessToken actions.
 */
export default function createValidateAccessTokenAction(axios: AxiosInstance) {
  return function validateAccessTokenAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: ValidateAccessTokenQueryParameters;
    payload: ValidateAccessTokenPayload;
  }) {
    const path = validateAccessTokenActionPath.original;

    return axios.put<ValidateAccessTokenResponse, ValidateAccessTokenResponse, ValidateAccessTokenPayload>(
      path,
      payload
    );
  };
}
