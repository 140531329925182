import { Flex, Button, Input } from '@mantine/core';
import { IconChevronDown, IconChevronRight, IconPaperclip, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import { DASHBOARD_PATH, HELPDESK_PAGE_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import ModalLocation from 'components/ModalLocation';
import ModalFile from 'components/ModalFile';
import ModalConfirmation from 'components/ModalConfirmation';

/**
 * Ticket page
 */
const Ticket: React.FC = () => {
  const { _t } = useLang();
  const [modalOpened, setModalOpened] = useState(false);
  const [secondModalOpened, setSecondModalOpened] = useState(false);
  const [requestModalOpened, setRequestModalOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [radioValue, setRadioValue] = useState('');

  const navigate = useNavigate();

  /**
   * Open Modal
   */
  const handleOpenModal = () => {
    setModalOpened(true);
  };

  /**
   * Close modal
   */
  const handleCloseModal = () => {
    setModalOpened(false);
  };

  /**
   * Open Second Modal
   */
  const handleOpenSecondModal = () => {
    setSecondModalOpened(true);
  };

  /**
   * Close Second Modal
   */
  const handleCloseSecondModal = () => {
    setSecondModalOpened(false);
  };

  /**
   * Open Send request model
   */
  const handleOpenRequestModal = () => {
    setRequestModalOpened(true);
  };

  /**
   * Close Send request model
   */
  const handleCloseRequestModal = () => {
    setRequestModalOpened(false);
  };

  /**
   * Handle Radio Change
   */
  const handleRadioChange = (value: string) => {
    setRadioValue(value);
    setSelectedLocation(value);
    setModalOpened(false);
  };

  /**
   * Handle Submit
   */
  const handleSubmit = () => {
    navigate(HELPDESK_PAGE_PATH.original);
    notifications.show({
      title: _t('Your request has been successfully entered.'),
      message: '',
      variant: 'green',
    });
  };

  return (
    <>
      <SimpleLayout>
        <Flex p="1rem" gap="1.5rem" direction="column">
          <H2SemiBold color={theme.black}>{_t('Information')}</H2SemiBold>
          <Input.Wrapper label={_t('The title of the problem')} required>
            <Input variant="primary" placeholder={_t('The title of the problem')} />
          </Input.Wrapper>
          <Input.Wrapper label={_t('Description of the problem')}>
            <Input variant="primary" placeholder={_t('Description of the problem')} />
          </Input.Wrapper>
          <Input.Wrapper label={_t('Location')}>
            <Input
              variant="primary"
              placeholder={_t('Choose')}
              onClick={handleOpenModal}
              value={selectedLocation}
              rightSection={<IconChevronDown />}
            />
          </Input.Wrapper>
          <Flex justify="space-between" align="center">
            <H2SemiBold color={theme.black}>{_t('Files')}</H2SemiBold>
            <Button variant="tertiary" rightSection={<IconPaperclip size="1.5rem" />} onClick={handleOpenSecondModal}>
              {_t('Add files')}
            </Button>
          </Flex>

          <Flex justify="space-between" gap="1rem">
            <Button
              variant="secondary"
              w="100%"
              size="lg"
              leftSection={<IconX size="1.5rem" />}
              onClick={() => navigate(DASHBOARD_PATH.original)}
            >
              {_t('Cancel')}
            </Button>
            <Button
              variant="primary"
              w="100%"
              size="lg"
              rightSection={<IconChevronRight size="1.5rem" />}
              onClick={handleOpenRequestModal}
            >
              {_t('Send')}
            </Button>
          </Flex>
        </Flex>
      </SimpleLayout>
      <ModalLocation
        modalOpened={modalOpened}
        handleCloseModal={handleCloseModal}
        radioValue={radioValue}
        handleRadioChange={handleRadioChange}
      />
      <ModalFile secondModalOpened={secondModalOpened} handleCloseSecondModal={handleCloseSecondModal} />
      <ModalConfirmation
        requestModalOpened={requestModalOpened}
        handleCloseRequestModal={handleCloseRequestModal}
        handleSubmit={handleSubmit}
        title={_t('Do you really want to send a request?')}
        cancelButtonText={_t('Cancel')}
        submitButtonText={_t('Yes, send')}
      />
    </>
  );
};

export default Ticket;
