import { Modal } from '@mantine/core';
import classNames from 'theme/components/Overlays/Modal/Modal.module.css';

export default Modal.extend({
  classNames,
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
