import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetFaqsPathParameters } from 'api/actions/get-faqs/get-faqs-path-parameters';
import { GetFaqsResponse } from 'api/actions/get-faqs/get-faqs-response';

type GetFaqsQueryParameters = Record<string, any>;

/**
 * The path of the GetFaqs action.
 */
export const getFaqsActionPath = new SweetPath<keyof GetFaqsPathParameters>('/help-desk/faqs', { path: '{param}' });

/**
 * Creates a contextualized GetFaqs actions.
 */
export default function createGetFaqsAction(axios: AxiosInstance) {
  return function getFaqsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetFaqsQueryParameters } = {}) {
    const path = getFaqsActionPath.original;

    return axios.get<GetFaqsResponse, GetFaqsResponse>(path, { params: query, headers });
  };
}
