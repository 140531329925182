import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetSensorStatsPathParameters } from 'api/actions/get-sensor-stats/get-sensor-stats-path-parameters';
import { GetSensorStatsResponse } from 'api/actions/get-sensor-stats/get-sensor-stats-response';

type GetSensorStatsQueryParameters = Record<string, any>;

/**
 * The path of the GetSensorStats action.
 */
export const getSensorStatsActionPath = new SweetPath<keyof GetSensorStatsPathParameters>(
  '/sensors/{sensorId}/stats/{functionName}/{granularity}',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetSensorStats actions.
 */
export default function createGetSensorStatsAction(axios: AxiosInstance) {
  return function getSensorStatsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: GetSensorStatsPathParameters;
    query?: GetSensorStatsQueryParameters;
  }) {
    const path = getSensorStatsActionPath.insert(parameters);

    return axios.get<GetSensorStatsResponse, GetSensorStatsResponse>(path, { params: query, headers });
  };
}
