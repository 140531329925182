import { determineGrammarForm } from 'lang/grammar-form';
import sk from 'lang/translations/sk.json';
import cs from 'lang/translations/cs.json';
import en from 'lang/translations/en.json';
import { sk as dateFnsSk, cs as dateFnsCs, enUS as dateFnsEn } from 'date-fns/locale';
import { Locale as DateFnsLocale } from 'date-fns';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

/**
 * The key of the translation.
 */
type TranslationKey = string;

/**
 * The value of the translation.
 */
type TranslationValue =
  | string
  | {
      one: string;
      few: string;
      many?: string;
      other: string;
    };

/**
 * Translation key-value pairs.
 */
type LanguageDictionary = Record<TranslationKey, TranslationValue>;

/**
 * Mapping of language code to the translation dictionary.
 */
const DICTIONARIES: Record<string, LanguageDictionary> = { sk, cs, en };

/**
 * Mapping of language code to the date-fns locale.
 */
const DATE_FNS_LOCALES: Record<string, DateFnsLocale> = { sk: dateFnsSk, cs: dateFnsCs, en: dateFnsEn };

/**
 * Context for language and translation management.
 */
interface ILangContext {
  lang: string;
  setLang: (lang: string) => void;
  dateFnsLocale: DateFnsLocale;
  _t: (msgid: string) => string;
  _tn: (msgid: string, msgidPlural: string, count: number) => string;
}

/**
 * Context for language and translation management.
 */
const LangContext = createContext<ILangContext>(undefined!);

/**
 * Hook for language and translation management.
 */
export function LangProvider({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = useState(() =>
    window.document.documentElement.lang === 'NGINX_TMPL_LANG'
      ? window.location.search.match(/lang=([a-z]{2})/)?.[1] ?? 'en'
      : window.document.documentElement.lang
  );

  const dateFnsLocale = useMemo(() => DATE_FNS_LOCALES[lang] || dateFnsEn, [lang]);
  const dictionary = useMemo(() => DICTIONARIES[lang] || {}, [lang]);

  /**
   * Translates the given string to the current language.
   */
  const _t = useCallback(
    (msgid: string): string => {
      const translation = dictionary[msgid];

      if (!translation) {
        return msgid;
      }

      if (typeof translation === 'string') {
        return translation;
      }

      return translation.one;
    },
    [dictionary]
  );

  /**
   * Translates the given string to the current language considering the given
   * count.
   */
  const _tn = useCallback(
    (msgid: string, msgidPlural: string, count: number): string => {
      const grammarForm = determineGrammarForm(count, lang);
      const translations = dictionary[msgid];

      if (!translations) {
        return count === 1 ? msgid : msgidPlural;
      }

      if (typeof translations === 'string') {
        return count === 1 ? translations : msgidPlural;
      }

      const translation = translations[grammarForm];

      if (!translation) {
        return count === 1 ? msgid : msgidPlural;
      }

      return translation;
    },
    [dictionary, lang]
  );

  const value = useMemo(() => ({ lang, setLang, dateFnsLocale, _t, _tn }), [lang, setLang, dateFnsLocale, _t, _tn]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

/**
 * Hook for language and translation management.
 */
export default function useLang() {
  return useContext(LangContext);
}
