import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { InitPasswordResetPayload } from 'api/actions/init-password-reset/init-password-reset-payload';
import { InitPasswordResetPathParameters } from 'api/actions/init-password-reset/init-password-reset-path-parameters';
import { InitPasswordResetResponse } from 'api/actions/init-password-reset/init-password-reset-response';

type InitPasswordResetQueryParameters = Record<string, any>;

/**
 * The path of the InitPasswordReset action.
 */
export const initPasswordResetActionPath = new SweetPath<keyof InitPasswordResetPathParameters>(
  '/reset-password/request',
  { path: '{param}' }
);

/**
 * Creates a contextualized InitPasswordReset actions.
 */
export default function createInitPasswordResetAction(axios: AxiosInstance) {
  return function initPasswordResetAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: InitPasswordResetQueryParameters;
    payload: InitPasswordResetPayload;
  }) {
    const path = initPasswordResetActionPath.original;

    return axios.post<InitPasswordResetResponse, InitPasswordResetResponse, InitPasswordResetPayload>(path, payload);
  };
}
