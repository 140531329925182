import { createTheme } from '@mantine/core';
import { components } from 'theme/components';

const theme = createTheme({
  fontFamily: 'SF Pro Display',
  primaryColor: 'primary',
  white: '#FFFFFF',
  black: '#000000',
  colors: {
    gray: [
      '#F3F4F5', // Gray 0
      '#F0F1F5', // Gray 1
      '#DFE2E6', // Gray 2
      '#CED2D9', // Gray 3
      '#B1B3C8', // Gray 4
      '#AFB5C1', // Gray 5
      '#7C7FA2', // Gray 6
      '#949DAC', // Gray 7
      '#44465F', // Gray 8
      '#727E92', // Gray 9
      '#525B6A', // Gray 10
      '#171821', // Gray 11
      '#0D0E14', // Gray 12
    ],
    primary: [
      '#551FFF', // Primary 0
      '#F0F3FE', // Primary 1
      '#E6EAFE', // Primary 2
      '#C9B8FF', // Primary 3
      '#B59EFF', // Primary 4
      '#3F5DF8', // Primary 5
      '#1D40F7', // Primary 6
      '#082CE2', // Primary 7
      '#551FFF', // Primary 8
      '#3600E0', // Primary 9
      '#2700A1', // Primary 10
    ],
  },
  components,
});

export default theme;
