import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetSensorAlertSettingsPathParameters } from 'api/actions/get-sensor-alert-settings/get-sensor-alert-settings-path-parameters';
import { GetSensorAlertSettingsResponse } from 'api/actions/get-sensor-alert-settings/get-sensor-alert-settings-response';

type GetSensorAlertSettingsQueryParameters = Record<string, any>;

/**
 * The path of the GetSensorAlertSettings action.
 */
export const getSensorAlertSettingsActionPath = new SweetPath<keyof GetSensorAlertSettingsPathParameters>(
  '/sensors/{sensorId}/alerts/settings',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetSensorAlertSettings actions.
 */
export default function createGetSensorAlertSettingsAction(axios: AxiosInstance) {
  return function getSensorAlertSettingsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: GetSensorAlertSettingsPathParameters;
    query?: GetSensorAlertSettingsQueryParameters;
  }) {
    const path = getSensorAlertSettingsActionPath.insert(parameters);

    return axios.get<GetSensorAlertSettingsResponse, GetSensorAlertSettingsResponse>(path, { params: query, headers });
  };
}
