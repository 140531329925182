import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { CloseControlledEntryPathParameters } from 'api/actions/close-controlled-entry/close-controlled-entry-path-parameters';
import { CloseControlledEntryResponse } from 'api/actions/close-controlled-entry/close-controlled-entry-response';

type CloseControlledEntryQueryParameters = Record<string, any>;

/**
 * The path of the CloseControlledEntry action.
 */
export const closeControlledEntryActionPath = new SweetPath<keyof CloseControlledEntryPathParameters>(
  '/controlled-entries/{controlledEntryId}/close',
  { path: '{param}' }
);

/**
 * Creates a contextualized CloseControlledEntry actions.
 */
export default function createCloseControlledEntryAction(axios: AxiosInstance) {
  return function closeControlledEntryAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: CloseControlledEntryPathParameters;
    query?: CloseControlledEntryQueryParameters;
  }) {
    const path = closeControlledEntryActionPath.insert(parameters);

    return axios.put<CloseControlledEntryResponse, CloseControlledEntryResponse>(path, { params: query, headers });
  };
}
