import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeleteLicensePlatePathParameters } from 'api/actions/delete-license-plate/delete-license-plate-path-parameters';
import { DeleteLicensePlateResponse } from 'api/actions/delete-license-plate/delete-license-plate-response';

type DeleteLicensePlateQueryParameters = Record<string, any>;

/**
 * The path of the DeleteLicensePlate action.
 */
export const deleteLicensePlateActionPath = new SweetPath<keyof DeleteLicensePlatePathParameters>(
  '/user/license-plates/{licensePlateId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeleteLicensePlate actions.
 */
export default function createDeleteLicensePlateAction(axios: AxiosInstance) {
  return function deleteLicensePlateAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeleteLicensePlatePathParameters;
    query?: DeleteLicensePlateQueryParameters;
  }) {
    const path = deleteLicensePlateActionPath.insert(parameters);

    return axios.delete<DeleteLicensePlateResponse, DeleteLicensePlateResponse>(path, { params: query, headers });
  };
}
