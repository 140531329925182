import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetLicensePlatesPathParameters } from 'api/actions/get-license-plates/get-license-plates-path-parameters';
import { GetLicensePlatesResponse } from 'api/actions/get-license-plates/get-license-plates-response';

type GetLicensePlatesQueryParameters = Record<string, any>;

/**
 * The path of the GetLicensePlates action.
 */
export const getLicensePlatesActionPath = new SweetPath<keyof GetLicensePlatesPathParameters>('/user/license-plates', {
  path: '{param}',
});

/**
 * Creates a contextualized GetLicensePlates actions.
 */
export default function createGetLicensePlatesAction(axios: AxiosInstance) {
  return function getLicensePlatesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetLicensePlatesQueryParameters } = {}) {
    const path = getLicensePlatesActionPath.original;

    return axios.get<GetLicensePlatesResponse, GetLicensePlatesResponse>(path, { params: query, headers });
  };
}
