import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SipCallConnectingPayload } from 'api/actions/sip-call-connecting/sip-call-connecting-payload';
import { SipCallConnectingPathParameters } from 'api/actions/sip-call-connecting/sip-call-connecting-path-parameters';
import { SipCallConnectingResponse } from 'api/actions/sip-call-connecting/sip-call-connecting-response';

type SipCallConnectingQueryParameters = Record<string, any>;

/**
 * The path of the SipCallConnecting action.
 */
export const sipCallConnectingActionPath = new SweetPath<keyof SipCallConnectingPathParameters>(
  '/sip/call/connecting',
  { path: '{param}' }
);

/**
 * Creates a contextualized SipCallConnecting actions.
 */
export default function createSipCallConnectingAction(axios: AxiosInstance) {
  return function sipCallConnectingAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: SipCallConnectingQueryParameters;
    payload: SipCallConnectingPayload;
  }) {
    const path = sipCallConnectingActionPath.original;

    return axios.post<SipCallConnectingResponse, SipCallConnectingResponse, SipCallConnectingPayload>(path, payload);
  };
}
