import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetArticlesPathParameters } from 'api/actions/get-articles/get-articles-path-parameters';
import { GetArticlesResponse } from 'api/actions/get-articles/get-articles-response';

type GetArticlesQueryParameters = Record<string, any>;

/**
 * The path of the GetArticles action.
 */
export const getArticlesActionPath = new SweetPath<keyof GetArticlesPathParameters>('/help-desk/articles', {
  path: '{param}',
});

/**
 * Creates a contextualized GetArticles actions.
 */
export default function createGetArticlesAction(axios: AxiosInstance) {
  return function getArticlesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetArticlesQueryParameters } = {}) {
    const path = getArticlesActionPath.original;

    return axios.get<GetArticlesResponse, GetArticlesResponse>(path, { params: query, headers });
  };
}
