import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P1SemiBold as P1SemiBoldStyle } from 'theme/typography';

interface P1SemiBoldProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P1SemiBold typography style.
 */
const P1SemiBold: React.FC<P1SemiBoldProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P1SemiBoldStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P1SemiBold;
