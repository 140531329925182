import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UpdateSensorAlertSettingsPayload } from 'api/actions/update-sensor-alert-settings/update-sensor-alert-settings-payload';
import { UpdateSensorAlertSettingsPathParameters } from 'api/actions/update-sensor-alert-settings/update-sensor-alert-settings-path-parameters';
import { UpdateSensorAlertSettingsResponse } from 'api/actions/update-sensor-alert-settings/update-sensor-alert-settings-response';

type UpdateSensorAlertSettingsQueryParameters = Record<string, any>;

/**
 * The path of the UpdateSensorAlertSettings action.
 */
export const updateSensorAlertSettingsActionPath = new SweetPath<keyof UpdateSensorAlertSettingsPathParameters>(
  '/sensors/{sensorId}/alerts/settings',
  { path: '{param}' }
);

/**
 * Creates a contextualized UpdateSensorAlertSettings actions.
 */
export default function createUpdateSensorAlertSettingsAction(axios: AxiosInstance) {
  return function updateSensorAlertSettingsAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UpdateSensorAlertSettingsPathParameters;
    query?: UpdateSensorAlertSettingsQueryParameters;
    payload: UpdateSensorAlertSettingsPayload;
  }) {
    const path = updateSensorAlertSettingsActionPath.insert(parameters);

    return axios.put<
      UpdateSensorAlertSettingsResponse,
      UpdateSensorAlertSettingsResponse,
      UpdateSensorAlertSettingsPayload
    >(path, payload);
  };
}
