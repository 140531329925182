import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { LogoutPathParameters } from 'api/actions/logout/logout-path-parameters';
import { LogoutResponse } from 'api/actions/logout/logout-response';

type LogoutQueryParameters = Record<string, any>;

/**
 * The path of the Logout action.
 */
export const logoutActionPath = new SweetPath<keyof LogoutPathParameters>('/logout', { path: '{param}' });

/**
 * Creates a contextualized Logout actions.
 */
export default function createLogoutAction(axios: AxiosInstance) {
  return function logoutAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: LogoutQueryParameters } = {}) {
    const path = logoutActionPath.original;

    return axios.post<LogoutResponse, LogoutResponse>(path, { params: query, headers });
  };
}
