import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { InitPasswordChangePayload } from 'api/actions/init-password-change/init-password-change-payload';
import { InitPasswordChangePathParameters } from 'api/actions/init-password-change/init-password-change-path-parameters';
import { InitPasswordChangeResponse } from 'api/actions/init-password-change/init-password-change-response';

type InitPasswordChangeQueryParameters = Record<string, any>;

/**
 * The path of the InitPasswordChange action.
 */
export const initPasswordChangeActionPath = new SweetPath<keyof InitPasswordChangePathParameters>(
  '/change-password/request',
  { path: '{param}' }
);

/**
 * Creates a contextualized InitPasswordChange actions.
 */
export default function createInitPasswordChangeAction(axios: AxiosInstance) {
  return function initPasswordChangeAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: InitPasswordChangeQueryParameters;
    payload: InitPasswordChangePayload;
  }) {
    const path = initPasswordChangeActionPath.original;

    return axios.post<InitPasswordChangeResponse, InitPasswordChangeResponse, InitPasswordChangePayload>(path, payload);
  };
}
