import { Box, Flex, Tabs } from '@mantine/core';
import { IconChevronLeft, IconScript } from '@tabler/icons-react';
import React from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from 'routes/paths';
import P2Medium from 'theme/components/Typography/P2Medium';
import DocumentItem from 'components/DocumentItem';

const documentsData = [
  {
    title: 'Dokumentácia k bytu č. 171/A',
    size: '128 kb | PDF',
    badges: [
      { variant: 'red', text: 'Súkromný dokument' },
      { variant: 'white', text: 'byt' },
    ],
  },
  {
    title: 'Dokumentácia k bytu č. 171/A',
    size: '128 kb | PDF',
    badges: [
      { variant: 'blue', text: 'Verejný dokument' },
      { variant: 'white', text: 'Areál' },
    ],
  },
];

/**
 * MyDocuments page
 */
const MyDocuments: React.FC = () => {
  const { _t } = useLang();
  return (
    <>
      <SimpleLayout>
        <Box p="1rem">
          <Flex direction="row" align="center">
            <Link
              to={DASHBOARD_PATH.original}
              style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
            >
              <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
              <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back')}</P2Medium>
            </Link>
          </Flex>
          <Flex gap="0.5rem">
            <IconScript size="2rem" color={theme.colors?.gray?.[12]} />
            <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('My Documents')}</H1SemiBold>
          </Flex>
        </Box>

        <Box>
          <Tabs defaultValue="contracts" variant="pills">
            <Tabs.List px="1rem">
              <Tabs.Tab value="contracts">{_t('Contracts')}</Tabs.Tab>
              <Tabs.Tab value="invoices">{_t('Invoices')}</Tabs.Tab>
              <Tabs.Tab value="documents">{_t('Other documents')}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="contracts" p="1rem">
              {documentsData.map((doc, index) => (
                <DocumentItem key={index} title={doc.title} size={doc.size} badges={doc.badges} />
              ))}
            </Tabs.Panel>
          </Tabs>
        </Box>
      </SimpleLayout>
    </>
  );
};

export default MyDocuments;
