import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UpdateUserSettingsPayload } from 'api/actions/update-user-settings/update-user-settings-payload';
import { UpdateUserSettingsPathParameters } from 'api/actions/update-user-settings/update-user-settings-path-parameters';
import { UpdateUserSettingsResponse } from 'api/actions/update-user-settings/update-user-settings-response';

type UpdateUserSettingsQueryParameters = Record<string, any>;

/**
 * The path of the UpdateUserSettings action.
 */
export const updateUserSettingsActionPath = new SweetPath<keyof UpdateUserSettingsPathParameters>('/user/settings', {
  path: '{param}',
});

/**
 * Creates a contextualized UpdateUserSettings actions.
 */
export default function createUpdateUserSettingsAction(axios: AxiosInstance) {
  return function updateUserSettingsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UpdateUserSettingsQueryParameters;
    payload: UpdateUserSettingsPayload;
  }) {
    const path = updateUserSettingsActionPath.original;

    return axios.put<UpdateUserSettingsResponse, UpdateUserSettingsResponse, UpdateUserSettingsPayload>(path, payload);
  };
}
