import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetArticlePathParameters } from 'api/actions/get-article/get-article-path-parameters';
import { GetArticleResponse } from 'api/actions/get-article/get-article-response';

type GetArticleQueryParameters = Record<string, any>;

/**
 * The path of the GetArticle action.
 */
export const getArticleActionPath = new SweetPath<keyof GetArticlePathParameters>('/help-desk/articles/{articleId}', {
  path: '{param}',
});

/**
 * Creates a contextualized GetArticle actions.
 */
export default function createGetArticleAction(axios: AxiosInstance) {
  return function getArticleAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: GetArticlePathParameters;
    query?: GetArticleQueryParameters;
  }) {
    const path = getArticleActionPath.insert(parameters);

    return axios.get<GetArticleResponse, GetArticleResponse>(path, { params: query, headers });
  };
}
