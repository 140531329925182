import { Button, Flex, Input } from '@mantine/core';
import React, { useState } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { ECV_PAGE_PATH } from 'routes/paths';
import { useForm } from '@mantine/form';
import { useApi } from 'api/api-context';
import panic from 'panic';

/**
 * EcvAdd page
 */
const EcvAdd: React.FC = () => {
  const { _t } = useLang();
  const navigate = useNavigate();
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      code:'',
      note:''
    },
  });

  /**
   * Handle back button
   */
  const handleClick = () => {
    navigate(ECV_PAGE_PATH.original);
  };

  return (
    <>
      <SimpleLayout>
        <form
          onSubmit={form.onSubmit((data) => {
            const createLicensePlate = getAction('CreateLicensePlate');

            setLoading(true);
            createLicensePlate({
              payload: {
                code: data.code,
                note: data.note,
              },
            })
              .then(() => {
                navigate(ECV_PAGE_PATH.original);
              })
              .catch(panic)
              .finally(() => setLoading(false));
          })}
        >
          <Flex p="1rem" gap="1.5rem" direction="column">
            <H2SemiBold color={theme.black}>{_t('New vehicle registration number')}</H2SemiBold>
            <Input.Wrapper label={_t('Vehicle registration number of your vehicle (without spaces)')} required>
              <Input variant="primary" placeholder={_t('Vehicle registration number')} {...form.getInputProps('code') } required/>
            </Input.Wrapper>
            <Input.Wrapper label={_t('Note to vehicle registration number (optional)')}>
              <Input variant="primary" placeholder={_t('Note')} {...form.getInputProps('note')} />
            </Input.Wrapper>
          </Flex>
          <Flex
            justify="space-between"
            gap="1rem"
            px="1rem"
            w="100%"
            style={{
              position: 'fixed',
              bottom: '2.5rem',
            }}
          >
            <Button
              variant="secondary"
              w="100%"
              size="lg"
              leftSection={<IconX size="1.5rem" />}
              onClick={handleClick}
              disabled={loading}
            >
              {_t('Cancel')}
            </Button>
            <Button
              variant="primary"
              w="100%"
              size="lg"
              rightSection={<IconChevronRight size="1.5rem" />}
              loading={loading}
              type="submit"
            >
              {_t('Yes, send')}
            </Button>
          </Flex>
        </form>
      </SimpleLayout>
    </>
  );
};

export default EcvAdd;
