import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SeenNotificationPathParameters } from 'api/actions/seen-notification/seen-notification-path-parameters';
import { SeenNotificationResponse } from 'api/actions/seen-notification/seen-notification-response';

type SeenNotificationQueryParameters = Record<string, any>;

/**
 * The path of the SeenNotification action.
 */
export const seenNotificationActionPath = new SweetPath<keyof SeenNotificationPathParameters>(
  '/user/notifications/{notificationId}/seen',
  { path: '{param}' }
);

/**
 * Creates a contextualized SeenNotification actions.
 */
export default function createSeenNotificationAction(axios: AxiosInstance) {
  return function seenNotificationAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: SeenNotificationPathParameters;
    query?: SeenNotificationQueryParameters;
  }) {
    const path = seenNotificationActionPath.insert(parameters);

    return axios.put<SeenNotificationResponse, SeenNotificationResponse>(path, { params: query, headers });
  };
}
