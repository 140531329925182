import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetCurrentUserPathParameters } from 'api/actions/get-current-user/get-current-user-path-parameters';
import { GetCurrentUserResponse } from 'api/actions/get-current-user/get-current-user-response';

type GetCurrentUserQueryParameters = Record<string, any>;

/**
 * The path of the GetCurrentUser action.
 */
export const getCurrentUserActionPath = new SweetPath<keyof GetCurrentUserPathParameters>('/user', { path: '{param}' });

/**
 * Creates a contextualized GetCurrentUser actions.
 */
export default function createGetCurrentUserAction(axios: AxiosInstance) {
  return function getCurrentUserAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetCurrentUserQueryParameters } = {}) {
    const path = getCurrentUserActionPath.original;

    return axios.get<GetCurrentUserResponse, GetCurrentUserResponse>(path, { params: query, headers });
  };
}
