import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetCamerasPathParameters } from 'api/actions/get-cameras/get-cameras-path-parameters';
import { GetCamerasResponse } from 'api/actions/get-cameras/get-cameras-response';

type GetCamerasQueryParameters = Record<string, any>;

/**
 * The path of the GetCameras action.
 */
export const getCamerasActionPath = new SweetPath<keyof GetCamerasPathParameters>('/cameras', { path: '{param}' });

/**
 * Creates a contextualized GetCameras actions.
 */
export default function createGetCamerasAction(axios: AxiosInstance) {
  return function getCamerasAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetCamerasQueryParameters } = {}) {
    const path = getCamerasActionPath.original;

    return axios.get<GetCamerasResponse, GetCamerasResponse>(path, { params: query, headers });
  };
}
