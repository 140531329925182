import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { GetSensorsPathParameters } from 'api/actions/get-sensors/get-sensors-path-parameters';
import { GetSensorsResponse } from 'api/actions/get-sensors/get-sensors-response';

type GetSensorsQueryParameters = Record<string, any>;

/**
 * The path of the GetSensors action.
 */
export const getSensorsActionPath = new SweetPath<keyof GetSensorsPathParameters>('/sensors', { path: '{param}' });

/**
 * Creates a contextualized GetSensors actions.
 */
export default function createGetSensorsAction(axios: AxiosInstance) {
  return function getSensorsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: GetSensorsQueryParameters } = {}) {
    const path = getSensorsActionPath.original;

    return axios.get<GetSensorsResponse, GetSensorsResponse>(path, { params: query, headers });
  };
}
