/**
 * 
 *  FooterIcon component
 */
export function FooterIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="89" height="36" viewBox="0 0 89 36" fill="none">
      <path
        d="M75.451 19.6277C74.2063 19.6277 73.2488 18.6223 73.2488 17.4255C73.2488 16.2287 74.2542 15.2234 75.451 15.2234C76.1692 15.2234 76.7915 14.6489 76.7915 13.883C76.7915 13.1649 76.217 12.5426 75.451 12.5426C74.2063 12.5426 73.2488 11.5372 73.2488 10.3404C73.2488 9.14363 74.2542 8.13831 75.451 8.13831C78.6108 8.13831 81.196 10.7234 81.196 13.883C81.196 17.0426 78.6586 19.6277 75.451 19.6277Z"
        fill="white" />
      <path
        d="M39.8792 5.88832C39.8792 5.17023 39.592 4.45215 39.0654 3.97343C38.5866 3.4947 38.0121 3.25534 37.3419 3.25534C36.6237 3.25534 35.9056 3.54257 35.4269 4.06917C34.9481 4.54789 34.7087 5.12236 34.7087 5.79257C34.7087 6.51066 34.996 7.22875 35.5226 7.70747C35.6662 7.85109 35.762 7.94683 35.9056 8.04257C36.1929 8.23406 36.1929 8.56917 35.9535 8.76066C35.4269 9.14364 35.0917 9.81385 35.0917 10.5319V22.5C35.0917 23.7447 36.0971 24.7022 37.294 24.7022C38.5387 24.7022 39.4962 23.6968 39.4962 22.5V10.4841C39.4962 9.76598 39.1611 9.14364 38.6345 8.71279C38.3951 8.5213 38.3951 8.13832 38.6345 7.9947C38.826 7.89896 39.0175 7.75534 39.1611 7.56385C39.6399 7.133 39.8792 6.55853 39.8792 5.88832Z"
        fill="white" />
      <path
        d="M62.9549 5.88832C62.9549 5.17023 62.6677 4.45215 62.141 3.97343C61.6623 3.4947 61.0878 3.25534 60.4175 3.25534C59.6994 3.25534 58.9813 3.54257 58.5025 4.06917C58.0238 4.54789 57.7844 5.12236 57.7844 5.79257C57.7844 6.51066 58.0717 7.22875 58.5983 7.70747C58.7419 7.85109 58.8377 7.94683 58.9813 8.04257C59.2685 8.23406 59.2685 8.56917 59.0292 8.76066C58.5026 9.14364 58.1674 9.81385 58.1674 10.5319V22.5C58.1674 23.7447 59.1728 24.7022 60.3697 24.7022C61.6144 24.7022 62.5719 23.6968 62.5719 22.5V10.4841C62.5719 9.76598 62.2368 9.14364 61.7102 8.71279C61.4708 8.5213 61.4708 8.13832 61.7102 7.9947C61.9017 7.89896 62.0932 7.75534 62.2368 7.56385C62.6677 7.133 62.907 6.55853 62.9549 5.88832Z"
        fill="white" />
      <path
        d="M45.0025 0C43.7578 0 42.8003 1.00532 42.8003 2.20213V20.1064C42.8003 21.3511 43.8057 22.3085 45.0025 22.3085C46.2473 22.3085 47.2048 21.3032 47.2048 20.1064V2.20213C47.2048 1.00532 46.1994 0 45.0025 0Z"
        fill="white" />
      <path
        d="M52.6629 5.31381C51.4182 5.31381 50.4607 6.31913 50.4607 7.51594V25.4202C50.4607 26.6649 51.4661 27.6223 52.6629 27.6223C53.9077 27.6223 54.8652 26.617 54.8652 25.4202V7.51594C54.9131 6.31913 53.9077 5.31381 52.6629 5.31381Z"
        fill="white" />
      <path
        d="M68.0299 9.86169C66.7851 9.86169 65.8276 10.867 65.8276 12.0638V33.7979C65.8276 35.0425 66.833 36 68.0299 36C69.2746 36 70.2321 34.9947 70.2321 33.7979V12.0638C70.28 10.867 69.2746 9.86169 68.0299 9.86169Z"
        fill="white" />
      <path
        d="M75.4513 27.383C74.2066 27.383 73.2491 26.3777 73.2491 25.1809C73.2491 23.984 74.2544 22.9787 75.4513 22.9787C80.4303 22.9787 84.4997 18.9096 84.4997 13.9309C84.4997 8.95213 80.4303 4.88298 75.4513 4.88298C73.2491 4.88298 71.1426 5.69681 69.5148 7.13298C68.6052 7.94681 67.1689 7.85107 66.4029 6.9415C65.5891 6.03192 65.6848 4.59575 66.5944 3.82979C69.0361 1.67554 72.1958 0.478729 75.4992 0.478729C82.9198 0.478729 88.9999 6.51064 88.9999 13.9787C88.952 21.3511 82.9198 27.383 75.4513 27.383Z"
        fill="white" />
      <path
        d="M13.3571 5.12231C12.639 4.64358 11.7772 4.30848 10.8197 4.11699C9.86223 3.9255 8.80898 3.82975 7.75573 3.82975H1.96287C0.861748 3.78188 0 4.64358 0 5.74465C0 6.84571 0.861748 7.70741 1.96287 7.70741H6.98974C7.42061 7.70741 7.89936 7.75528 8.33023 7.80316C8.80898 7.85103 9.23986 7.94677 9.62285 8.13826C10.0059 8.32975 10.341 8.56911 10.5804 8.90422C10.8197 9.23933 10.9634 9.67018 10.9634 10.1968C10.9634 10.6755 10.8676 11.1063 10.6761 11.4415C10.4846 11.7766 10.2452 12.0159 9.95798 12.2074C9.67073 12.3989 9.28773 12.5425 8.90473 12.5904C8.52173 12.6861 8.13873 12.6861 7.70786 12.6861H1.96287C0.861748 12.6861 0 13.5478 0 14.6489V22.7872C0 23.8883 0.861748 24.75 1.96287 24.75H2.68099C3.78212 24.75 4.64387 23.8883 4.64387 22.7872V18.5266C4.64387 17.4255 5.50561 16.5638 6.60674 16.5638H8.04298C9.1441 16.5638 10.1495 16.468 11.107 16.2766C12.0645 16.0851 12.8783 15.7021 13.5486 15.2234C14.2188 14.7446 14.7933 14.0744 15.1763 13.2606C15.5593 12.4468 15.7508 11.3936 15.7508 10.1968C15.7508 8.95209 15.5593 7.94677 15.1285 7.08507C14.6497 6.22337 14.0752 5.60103 13.3571 5.12231Z"
        fill="white" />
      <path
        d="M30.9272 11.9202C30.5442 11.2979 30.0175 10.8192 29.2994 10.4841C28.6292 10.149 27.7195 9.95748 26.5705 9.95748C26.0439 9.95748 25.5652 10.0053 25.0864 10.149C24.6555 10.2926 24.2247 10.4841 23.8895 10.6756C23.5544 10.9149 23.2672 11.1543 23.0278 11.3936C22.7884 11.6809 22.6448 11.9681 22.549 12.2075H22.5012V4.59577C22.5012 3.35109 21.4958 2.39365 20.2989 2.39365C19.0542 2.39365 18.0967 3.39897 18.0967 4.59577V22.5C18.0967 23.7447 19.1021 24.7022 20.2989 24.7022C21.5437 24.7022 22.5012 23.6968 22.5012 22.5V17.5692C22.5012 17.0905 22.549 16.6596 22.5969 16.2288C22.6448 15.7979 22.7884 15.4149 22.9799 15.0798C23.1714 14.7447 23.4587 14.4575 23.7938 14.266C24.1289 14.0745 24.6077 13.9788 25.1822 13.9788C25.7567 13.9788 26.1875 14.0745 26.4748 14.3139C26.762 14.5532 27.0014 14.8405 27.145 15.2234C27.2887 15.6064 27.3844 15.9894 27.4323 16.4681C27.4802 16.899 27.4802 17.3298 27.4802 17.7607V22.5C27.4802 23.7447 28.4855 24.7022 29.6824 24.7022C30.9272 24.7022 31.8846 23.6968 31.8846 22.5V16.8032C31.8846 15.8458 31.8368 14.9362 31.6931 14.1224C31.5495 13.2607 31.3102 12.5426 30.9272 11.9202Z"
        fill="white" />
    </svg>;
  }