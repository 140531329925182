/**
 * HeaderIcon component.
 */
export function HeaderIcon() {
  return (
    <svg width="172" height="62" viewBox="0 0 172 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3512_597)">
        <path
          d="M46.5308 13.957H49.6777C52.9911 13.957 55.1319 15.9991 55.1319 18.975C55.1319 21.9509 52.9873 24.0234 49.6777 24.0234H46.5308V13.957ZM49.6626 22.5734C52.019 22.5734 53.479 21.0627 53.479 18.975C53.479 16.8874 52.019 15.4222 49.6626 15.4222H48.1421V22.5734H49.6626Z"
          fill="#D6B69F"
        />
        <path
          d="M57.0458 13.957H63.6422V15.4032H58.6571V17.9843H62.4092V19.385H58.6571V22.5734H63.8238V24.0234H57.0458V13.957Z"
          fill="#D6B69F"
        />
        <path d="M65.6886 13.957H67.2999V22.5734H71.8879V24.0234H65.6886V13.957Z" fill="#D6B69F" />
        <path d="M73.9682 15.407H70.5981V13.957H78.9685V15.407H75.5795V24.0234H73.9682V15.407Z" fill="#D6B69F" />
        <path
          d="M85.3494 22.3457H80.837L80.1221 24.0234H78.4049L82.8265 13.79H83.375L87.7966 24.0234H86.0642L85.3494 22.3457ZM83.0988 16.7963L81.4119 20.9602H84.7707L83.0988 16.7963Z"
          fill="#D6B69F"
        />
        <path
          d="M49.4735 47.8874V45.682H46.856V47.8874H46.2773V43.1123H46.856V45.1506H49.4735V43.1123H50.0559V47.8874H49.4735Z"
          fill="#182447"
        />
        <path
          d="M51.1869 45.4998C51.1869 44.8052 51.429 44.2055 51.9093 43.7082C52.3897 43.2072 52.9911 42.9604 53.7135 42.9604C54.4359 42.9604 55.0752 43.211 55.5404 43.7082C56.0019 44.2093 56.2364 44.8052 56.2364 45.4998C56.2364 46.1944 55.9943 46.8056 55.5101 47.299C55.026 47.7925 54.4284 48.043 53.7173 48.043C53.3012 48.043 52.9306 47.9671 52.5977 47.8152C52.2649 47.6634 52.0001 47.4622 51.7996 47.2117C51.5992 46.9612 51.4479 46.6917 51.3457 46.3994C51.2436 46.1071 51.1907 45.8073 51.1907 45.4998M51.7921 45.4998C51.7921 46.0806 51.9774 46.5626 52.3443 46.946C52.7112 47.3294 53.1689 47.523 53.7135 47.523C54.2582 47.523 54.7158 47.3294 55.0865 46.946C55.4572 46.5626 55.6425 46.0768 55.6425 45.4998C55.6425 44.9229 55.4648 44.437 55.1054 44.0536C54.7461 43.6665 54.2847 43.4767 53.7173 43.4767C53.1499 43.4767 52.715 43.6665 52.3481 44.0498C51.9812 44.4332 51.7959 44.9153 51.7959 45.4998"
          fill="#182447"
        />
        <path
          d="M61.7208 43.1123L62.2428 47.8911H61.6755L61.4031 45.1013C61.3615 44.6382 61.3237 44.2168 61.2934 43.8335H61.2707C61.1837 44.122 61.0476 44.5471 60.866 45.1089L59.9393 47.8911H59.4363L58.5058 45.0329C58.2675 44.3004 58.1389 43.9018 58.1238 43.8335H58.0973C58.0633 44.3042 58.0292 44.7027 57.9914 45.0253L57.6964 47.8911H57.1366L57.6888 43.1123H58.3923L59.4249 46.1868C59.5308 46.4943 59.6178 46.7866 59.6821 47.0637H59.7086C59.7654 46.8359 59.8523 46.5437 59.9734 46.1868L61.0135 43.1123H61.7133H61.7208Z"
          fill="#182447"
        />
        <path
          d="M66.0139 43.1123V43.6437H63.979V45.1506H65.9345V45.682H63.979V47.3559H66.0895V47.8874H63.4003V43.1123H66.0139Z"
          fill="#182447"
        />
        <path
          d="M69.4974 47.8874V43.1085H70.5527C71.0406 43.1085 71.4227 43.2186 71.6988 43.4387C71.9749 43.6589 72.1111 43.9549 72.1111 44.3269C72.1111 44.8166 71.8539 45.1544 71.3357 45.3442V45.3594C71.695 45.3973 71.9711 45.5264 72.164 45.7503C72.3569 45.9743 72.4553 46.2476 72.4553 46.5702C72.4553 46.9991 72.3077 47.3256 72.0127 47.5495C71.7177 47.7735 71.3357 47.8874 70.8704 47.8874H69.5012H69.4974ZM71.5134 44.3763C71.5134 44.1295 71.4264 43.9436 71.2487 43.8221C71.0709 43.7006 70.8402 43.6399 70.5489 43.6399H70.0724V45.1468H70.5905C70.8742 45.1468 71.0974 45.0747 71.2638 44.9342C71.4302 44.79 71.5097 44.604 71.5097 44.3763M71.8463 46.5285C71.8463 46.2628 71.7555 46.054 71.574 45.906C71.3924 45.7579 71.1352 45.682 70.8061 45.682H70.0724V47.3559H70.9045C71.2146 47.3559 71.4529 47.28 71.608 47.132C71.7669 46.984 71.8463 46.7828 71.8463 46.5285Z"
          fill="#182447"
        />
        <path
          d="M74.4674 47.8874V45.9401L72.9734 43.1085H73.624L74.5317 44.8583C74.6452 45.0747 74.7284 45.2569 74.7852 45.4125H74.8003C74.8343 45.3366 74.8797 45.2341 74.9478 45.1089C75.0121 44.9798 75.0537 44.9001 75.065 44.8659L75.9312 43.1085H76.5326L75.0537 45.9325V47.8874H74.475H74.4674Z"
          fill="#182447"
        />
        <path
          d="M79.4488 43.6437V43.1123H82.834V43.6171L80.0237 47.3559H82.9475V47.8874H79.3315V47.3901L82.1456 43.6437H79.4488Z"
          fill="#182447"
        />
        <path
          d="M86.7186 43.1123V43.6437H84.6875V45.1506H86.6392V45.682H84.6875V47.3559H86.7943V47.8874H84.105V43.1123H86.7186Z"
          fill="#182447"
        />
        <path d="M88.6854 43.1123H88.1067V47.8911H88.6854V43.1123Z" fill="#182447" />
        <path d="M90.8981 47.8874V43.6437H89.6499V43.1123H92.7288V43.6437H91.4768V47.8874H90.8981Z" fill="#182447" />
        <path
          d="M95.4634 45.3404H98.1149V45.4049C98.1149 46.1755 97.8955 46.8094 97.4567 47.3028C97.018 47.7963 96.4279 48.0468 95.6941 48.0468C94.9604 48.0468 94.3854 47.7963 93.924 47.2952C93.4625 46.7942 93.228 46.1982 93.228 45.5036C93.228 44.809 93.4625 44.2055 93.9353 43.7082C94.4081 43.211 94.9944 42.9604 95.6979 42.9604C96.2615 42.9604 96.7343 43.0857 97.1201 43.34C97.5021 43.5943 97.7782 43.9284 97.9484 44.3497H97.3054C97.1806 44.0878 96.9764 43.879 96.6965 43.7196C96.4166 43.5602 96.0837 43.4805 95.6979 43.4805C95.1343 43.4805 94.6805 43.6741 94.34 44.0574C93.9996 44.4408 93.8256 44.9153 93.8256 45.477C93.8256 46.0882 94.0072 46.5854 94.3703 46.9612C94.7334 47.3408 95.1911 47.5305 95.7395 47.5305C96.2199 47.5305 96.6208 47.3749 96.9461 47.0637C97.2714 46.7524 97.4567 46.3577 97.4983 45.8756H95.4596V45.3442L95.4634 45.3404Z"
          fill="#182447"
        />
        <path
          d="M101.859 43.1123V43.6437H99.8283V45.1506H101.78V45.682H99.8283V47.3559H101.935V47.8874H99.2496V43.1123H101.859Z"
          fill="#182447"
        />
        <path d="M103.826 43.1123H103.248V47.8911H103.826V43.1123Z" fill="#182447" />
        <path
          d="M107.979 44.0916H107.359C107.321 43.9056 107.234 43.7538 107.098 43.6361C106.962 43.5184 106.784 43.4615 106.572 43.4615C106.33 43.4615 106.137 43.5298 105.997 43.6665C105.854 43.8031 105.786 43.9739 105.786 44.1865C105.786 44.399 105.861 44.5737 106.012 44.7065C106.164 44.8356 106.387 44.9456 106.686 45.0367C107.204 45.1924 107.567 45.3935 107.783 45.6479C107.998 45.8984 108.104 46.221 108.104 46.6082C108.104 46.9953 107.972 47.3256 107.707 47.6179C107.442 47.9063 107.075 48.0506 106.61 48.0506C106.202 48.0506 105.854 47.9405 105.57 47.7241C105.286 47.5078 105.093 47.1813 104.999 46.7448H105.634C105.774 47.2762 106.103 47.5457 106.618 47.5457C106.901 47.5457 107.124 47.4546 107.28 47.2724C107.438 47.0902 107.518 46.8815 107.518 46.6423C107.518 46.3766 107.435 46.1603 107.268 45.9933C107.102 45.8263 106.845 45.6896 106.497 45.5833C106.054 45.4467 105.729 45.2683 105.517 45.0481C105.305 44.828 105.199 44.5395 105.199 44.1903C105.199 43.8411 105.328 43.5526 105.585 43.3173C105.842 43.0819 106.171 42.9642 106.572 42.9642C106.973 42.9642 107.272 43.0705 107.525 43.2869C107.779 43.5032 107.93 43.7727 107.983 44.0992"
          fill="#182447"
        />
        <path d="M110.124 47.8874V43.6437H108.876V43.1123H111.951V43.6437H110.703V47.8874H110.124Z" fill="#182447" />
        <path
          d="M20.6556 0H18.6207C8.35149 0 0 8.38105 0 18.6827V43.3135C0 53.6152 8.35149 61.9962 18.6207 61.9962H20.6556C30.9209 61.9962 39.2762 53.6152 39.2762 43.3135V18.6827C39.2724 8.38105 30.9209 0 20.6556 0ZM38.8753 43.3135C38.8753 53.395 30.7016 61.5976 20.6556 61.5976H18.6207C8.57465 61.5976 0.400932 53.395 0.400932 43.3135V31.1974H2.38668V43.1161C2.38668 52.169 9.7245 59.5328 18.7455 59.5328H20.5308C29.5517 59.5328 36.8895 52.169 36.8895 43.1161V31.1974H38.8791V43.3135H38.8753ZM2.78383 43.1199V18.8801C2.78383 10.0474 9.94387 2.86201 18.7455 2.86201H20.5308C29.3323 2.86201 36.4924 10.0474 36.4924 18.8801V43.1199C36.4924 51.9526 29.3323 59.138 20.5308 59.138H18.7455C9.94387 59.138 2.78383 51.9526 2.78383 43.1199ZM38.8753 30.7988H36.8858V18.8801C36.8858 9.82723 29.5479 2.46345 20.527 2.46345H18.7417C9.72449 2.46345 2.38668 9.82723 2.38668 18.8801V30.7988H0.39715V18.6827C0.39715 8.6012 8.57087 0.398555 18.6207 0.398555H20.6556C30.7016 0.398555 38.8753 8.6012 38.8753 18.6827V30.7988Z"
          fill="#D6B69F"
        />
        <path
          d="M10.6777 42.8124L28.5948 42.8238C29.3135 42.8238 29.9565 42.3683 30.2326 41.6661C30.5087 40.9639 30.3574 40.163 29.8468 39.624L21.5407 31.0038L29.3815 22.376C30.0472 21.6738 30.1002 20.5237 29.3967 19.7304C29.0714 19.366 28.6024 19.1762 28.1296 19.1762L11.4152 19.2104C10.6966 19.2104 10.0536 19.6696 9.78125 20.3719C9.50514 21.0741 9.66022 21.875 10.1671 22.414L16.1432 28.9389L18.3408 26.6159L14.6378 22.4937L24.9032 22.4747L9.42571 39.6164C8.91887 40.1554 8.76757 40.9563 9.03991 41.6585C9.31602 42.3607 9.95902 42.8162 10.6777 42.8162M15.6931 37.4452L19.3923 33.3761L25.3722 39.5367L13.904 39.5291L15.6969 37.4414L15.6931 37.4452Z"
          fill="#D6B69F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3512_597">
          <rect width="112" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
