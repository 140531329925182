import { useState } from 'react';
import { Select } from '@mantine/core';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import useLang from 'lang';

/**
 *  LanguageMenu component
 */
function LanguageMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { lang, setLang } = useLang();

  /**
   * Change lang
   */
  const handleChange = (value: string) => {
    const langCode = value.split(' ')[1].toLowerCase();
    setLang(langCode);
  };

  //Nastavenie defaultValue podla nasteveného jazyka
  let langParameter;
  switch (lang) {
    case 'sk':
      langParameter = `🇸🇰 ${lang.toUpperCase()}`;
      break;
    case 'en':
      langParameter = `🇬🇧 ${lang.toUpperCase()}`;
      break;
    case 'cs':
      langParameter = `🇨🇿 ${lang.toUpperCase()}`;
      break;
    default:
      langParameter = `🇬🇧 ${lang.toUpperCase()}`;
      break;
  }

  return (
    <Select
      variant="secondary"
      data={['🇸🇰 SK', '🇬🇧 EN', '🇨🇿 CS']}
      rightSection={isOpen ? <IconChevronUp /> : <IconChevronDown />}
      onDropdownOpen={() => setIsOpen(true)}
      onDropdownClose={() => setIsOpen(false)}
      defaultValue={langParameter} // Po zmene jazyka zmizne hore v selecte jazyk
      withCheckIcon={false}
      onChange={(value) => handleChange(value ?? '🇬🇧 EN')}
    />
  );
}

export default LanguageMenu;
