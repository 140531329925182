import { Notification } from '@mantine/core';
import classNames from 'theme/components/Feedback/Notification/Notification.module.css';

export default Notification.extend({
  classNames,
  defaultProps: {
    variant: 'green',
  },
});


