import { Flex, Box } from '@mantine/core';
import {
  IconArmchair2,
  IconChevronLeft,
  IconChevronRight,
  IconKey,
  IconRelationOneToOne,
  IconUsers,
} from '@tabler/icons-react';
import MenuItem from 'components/MenuItem';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH, ECV_PAGE_PATH } from 'routes/paths';
import theme from 'theme';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import P2Medium from 'theme/components/Typography/P2Medium';

/**
 * House page
 */
const House = () => {
  const { _t } = useLang();
  return (
    <>
      <SimpleLayout>
        <Box>
          <Box p="1rem">
            <Flex direction="row" align="center">
              <Link
                to={DASHBOARD_PATH.original}
                style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
              >
                <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
                <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back')}</P2Medium>
              </Link>
            </Flex>
            <Flex gap="0.5rem">
              <IconArmchair2 size="2rem" color={theme.colors?.gray?.[12]} />
              <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('My household')}</H1SemiBold>
            </Flex>
          </Box>
          {/*EČV */}
          <MenuItem
            to={ECV_PAGE_PATH.original}
            icon={IconRelationOneToOne}
            title={_t('My registration numbers')}
            description={_t('List and management of registration numbers')}
            iconRight={IconChevronRight}
          />
          {/*Zoznam čipov */}
          <MenuItem
            to={''}
            icon={IconKey}
            title={_t('List of chips')}
            description={_t('List and management of chips')}
            iconRight={IconChevronRight}
          />
          {/*Používatelia v domácnosti */}
          <MenuItem
            to={''}
            icon={IconUsers}
            title={_t('Household users')}
            description={_t('List and management of users')}
            iconRight={IconChevronRight}
          />
        </Box>
      </SimpleLayout>
    </>
  );
};

export default House;
